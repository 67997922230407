<template>
  <div class="flex flex-col">
    <div class="overflow-x-auto">
      <div class="py-2 align-middle inline-block min-w-full">
        <div
          class="overflow-hidden border border-gray-200 border-radious_custom"
        >
          <table class="min-w-full divide-y divide-gray-200 table-auto">
            <thead class="bg-th-primary-200-l">
              <tr>
                <th
                  scope="col"
                  class="
                    px-6
                    py-3
                    text-left text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                >
                  {{translations.root_product}}
                </th>
                <th v-if="typeOfRequest === 'return' || showRequestSolution"
                  scope="col"
                  class="
                    px-6
                    py-3
                    text-left text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                >
                  {{
                    typeOfRequest === "return"
                      ? translations.form_step_2_return_reason
                      : translations.form_step_2_claim_solution
                  }}
                </th>
                <th
                  scope="col"
                  class="
                    px-6
                    py-3
                    text-left text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                >
                  {{ translations.root_product_code }}
                </th>
                <th
                  scope="col"
                  class="
                    px-6
                    py-3
                    text-left text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                >
                  {{ translations.form_step_2_quantity }}
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <template v-for="item in items" :key="item.id">
                <tr>
                  <td
                    class="
                      px-6
                      py-4
                      whitespace-nowrap
                      text-sm
                      font-medium
                      text-gray-900
                    "
                  >
                    {{ item.name }}
                  </td>
                  <td v-if="typeOfRequest === 'return' || showRequestSolution" class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{
                      item.reasonOfReturning?.name || item.requestSolution?.name
                    }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ item.productCode }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ item.quantityReturn }}
                  </td>
                </tr>
                <tr v-if="typeOfRequest === 'complain'">
                  <td
                    colspan="4"
                    class="py-2 text-sm text-gray-500"
                  >
                    <span class="px-6 pb-1 flex items-start" tabindex="-1">
                      <span class="mr-2">{{translations.form_step_2_claim_reason}}: </span>
                      <p>{{ item.reasonOfComplain }}</p>
                    </span>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  name: 'FormClaimProductsManual',
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  setup() {
    const store = useStore();
    const translations = computed(() => store.state.translations);
    const typeOfRequest = computed(() => store.state.form.typeOfRequest);
    const showRequestSolution = computed(() => store.state.settings.show_claim_solutions)

    return {
      translations,
      typeOfRequest,
      showRequestSolution
    }
  }
}
</script>

<style scoped></style>
