<template>
  <Listbox v-model="value">
    <ListboxLabel
      :class="[
        hiddenLabel ? 'sr-only' : '',
        'text-sm font-medium text-gray-700'
      ]"
    >
      {{ label }}
      <sup v-if="isRequired" class="inline text-yellow-600">*</sup>
    </ListboxLabel>
    <div class="relative mt-1 text-sm">
      <ListboxButton
        :class="[
          errorMessage ? 'border-red-500' : '',
          'bg-white relative w-full border border-gray-200 border-radious_custom shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-th-primary-200 focus:border-th-primary-200'
        ]"
      >
        <span class="block truncate">{{
          value?.[labelProp] || translations.root_select_option
        }}</span>
        <span
          class="
            absolute
            inset-y-0
            right-0
            flex
            items-center
            pr-2
            pointer-events-none
          "
        >
          <SelectorIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
        </span>
      </ListboxButton>

      <transition
        leave-active-class="transition duration-100 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="
            z-10
            absolute
            w-full
            py-1
            mt-1
            overflow-auto
            text-base
            bg-white
            rounded-md
            shadow-lg
            max-h-60
            ring-1 ring-black ring-opacity-5
            focus:outline-none
            sm:text-sm
          "
        >
          <!--disabled option - on IOS prevent error-->
          <ListboxOption value="Vyberte možnost" as="template" :disabled="true">
            <li
              class="
                text-gray-500
                cursor-default
                select-none
                relative
                py-2
                pl-10
                pr-4
              "
            >
              <span class="font-normal block truncate text-sm"
                >{{translations.root_select_option}}</span
              >
            </li>
          </ListboxOption>
          <ListboxOption
            v-slot="{ active, selected }"
            v-for="option in options"
            :key="option[keyProp]"
            :value="option"
            as="template"
          >
            <li
              :class="[
                active ? 'bg-th-primary-200-l' : 'text-gray-900',
                'cursor-default select-none relative py-2 pl-10 pr-4'
              ]"
            >
              <span
                :class="[
                  selected ? 'font-medium' : 'font-normal',
                  'block truncate text-sm'
                ]"
                >{{ option[labelProp] }}</span
              >
              <span
                v-if="selected"
                class="
                  absolute
                  inset-y-0
                  left-0
                  flex
                  items-center
                  pl-3
                  text-amber-600
                "
              >
                <CheckIcon class="w-5 h-5" aria-hidden="true" />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
    <p class="text-xs text-red-400 mt-1" v-show="errorMessage">
      {{ errorMessage }}
    </p>

    <!--    <span class="text-red-500">{{ errorMessage }}</span>-->
  </Listbox>
</template>

<script>
import { computed, watch } from 'vue'
import {
  Listbox,
  ListboxLabel,
  ListboxButton,
  ListboxOptions,
  ListboxOption
} from '@headlessui/vue'
import { CheckIcon, SelectorIcon } from '@heroicons/vue/solid'
import { useField } from 'vee-validate'
import { useStore } from 'vuex'

export default {
  components: {
    Listbox,
    ListboxLabel,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    CheckIcon,
    SelectorIcon
  },
  props: {
    options: {
      type: Array,
      required: true
    },
    modelValue: {
      type: null
    },
    keyProp: {
      type: String,
      required: true
    },
    label: {
      type: String
    },
    labelProp: {
      type: String,
      required: true
    },
    hiddenLabel: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: true
    },
    name: {
      type: String,
      required: true
    },
    rules: {
      type: null,
      default: null
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const store = useStore()
    const translations = computed(() => store.state.translations)

    const { value, errorMessage } = useField(props.name, props.rules, {
      initialValue: props.modelValue
    })

    // Sync v-model binding with vee-validate model changes
    watch(value, (newValue) => {
      if (newValue !== props.modelValue) {
        emit('update:modelValue', newValue)
      }
    })

    // Sync vee-validate's model with external model changes.
    watch(
      () => props.modelValue,
      (newModel) => {
        if (newModel !== value.value) {
          value.value = newModel
        }
      }
    )

    return {
      value,
      errorMessage,
      translations
    }
  }
}
</script>
