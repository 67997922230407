<template>
  <TheHeader>
    <template v-slot:header>{{ translations.step1_intro_heading }}</template>
    <template v-slot:description>{{ translations.step1_intro_tooltip }}</template>
  </TheHeader>
  <div class="max-w-screen-md mx-auto">
    <div class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2">
      <div
          v-for="option in options"
          :key="option.id"
          class="
          flex flex-col
          justify-between
          bg-white
          p-5
          px-4
          sm:pt-6 sm:px-6
          border
          hover:border-th-primary-200
          shadow
          border-radious_custom
          overflow-hidden
          group
        "
      >
        <BaseCardOption :option="option">
          <template v-slot:icon>
            <component
                :is="option.icon"
                class="
                w-14
                text-th-primary-200
                mx-auto
              "
                aria-hidden="true"
            />
          </template>
          <template v-slot:button>
            <BaseButtonPrimary v-if='isStoreReturn' path-name="RequestOptions" :params="{ slug: option.paramSlug }">
              {{ translations.root_continue }}
            </BaseButtonPrimary>
            <BaseButton v-else @btn-click="handleClick(option.paramSlug)">{{
                translations.root_continue
              }}
            </BaseButton>
          </template>
        </BaseCardOption>
      </div>
    </div>
  </div>
</template>

<script>
import {useStore} from "vuex";
import TheHeader from '../components/TheHeader'
import BaseCardOption from '../components/base/BaseCardOption'
import {RefreshIcon} from '@heroicons/vue/outline'
import {XCircleIcon} from '@heroicons/vue/solid'
import {computed} from "vue";
import BaseButtonPrimary from "../components/base/BaseButtonPrimary";
import BaseButton from "@/components/base/BaseButton";
import useSessionStore from "@/composables/useSessionStore";
import {useRouter} from "vue-router";

export default {
  name: 'Home',
  components: {BaseButtonPrimary, TheHeader, BaseCardOption, RefreshIcon, BaseButton},
  setup() {
    const store = useStore()
    const router = useRouter()
    const { setSessionStore } = useSessionStore()
    const translations = computed(() => store.state.translations)
    const isStoreReturn = computed(() => store.state.settings.store_return_available)
    const options = computed(() => {
      return [
        {
          id: 1,
          name: translations.value.return_type_heading,
          description: translations.value.return_type_tooltip,
          icon: RefreshIcon,
          paramSlug: "vraceni",
          color: "btn-light"
        },
        {
          id: 2,
          name: translations.value.claim_type_heading,
          description: translations.value.claim_type_tooltip,
          icon: XCircleIcon,
          paramSlug: "reklamace",
          color: "btn-light"
        }
      ];
    })

    const handleClick = (request) => {
      console.log(request)
      // smazaní čísla objednávky a emailu z předešlého hledání
      if (sessionStorage.getItem('order_keys')) {
        sessionStorage.removeItem('order_keys')
      }
      store.dispatch('setTypeOfRequest', request)
      //uložení typu požadavku (reklamace, vrácení) do session store
      setSessionStore('typeOfRequest', request)
      router.push({name: 'OrderSearch', params: {slug : request}})
    }


    return {options, translations, handleClick, isStoreReturn}
  }
}
</script>
