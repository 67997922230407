<template>
  <TheHeader>
    <template v-slot:header>{{ selectedOption.header }}</template>
    <template v-slot:description>{{ selectedOption.description }}</template>
  </TheHeader>
  <div class="max-w-screen-md mx-auto">
    <div class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2">
      <div
        v-for="option in selectedOption.options"
        :key="option.id"
        class="
          flex flex-col
          justify-between
          bg-white
          p-5
          px-4
          sm:pt-6 sm:px-6
          border
          hover:border-th-primary-200
          shadow
          border-radious_custom
          overflow-hidden
          group
        "
      >
        <BaseCardOption :option="option">
          <template v-slot:icon>
            <component
              :is="option.icon"
              class="
                w-14
                text-th-primary-200
                mx-auto
              "
              aria-hidden="true"
            />
          </template>
          <template v-slot:button>
            <BaseButton @btn-click="handleClick(option.pathName, option.requestType)">{{ option.buttonText }}</BaseButton>
          </template>
        </BaseCardOption>
      </div>
    </div>
    <!--    Postup při vyřízení na prodejně-->
    <transition name="fade">
      <div
        v-if="isReturnSteps"
        class="mt-5 sm:mt-10 p-2 pl-4 border-l-2 border-th-primary-200"
      >
        <RequestOptionsInpresonGuide>
          <template v-slot:heading>
            {{ selectedOption.inpersonGuide.heading}}
          </template>
          <template v-slot:steps>
            <p>{{ selectedOption.inpersonGuide.steps}}</p>
          </template>
        </RequestOptionsInpresonGuide>
      </div>
    </transition>
  </div>
  <BaseLink>{{ translations.root_back}}</BaseLink>
</template>

<script>
import { useRoute, useRouter } from 'vue-router'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import useSessionStore from '../composables/useSessionStore'
import TheHeader from '../components/TheHeader'
import BaseCardOption from '../components/base/BaseCardOption'
import BaseLink from '../components/base/BaseLink'
import RequestOptionsInpresonGuide from '../components/RequestOptionsInpresonGuide'
import { DocumentTextIcon } from '@heroicons/vue/solid'
import { HomeIcon } from '@heroicons/vue/outline'
import BaseButton from "../components/base/BaseButton";

export default {
  name: 'RequestOptions',
  components: {
    BaseButton,
    RequestOptionsInpresonGuide,
    TheHeader,
    BaseCardOption,
    BaseLink,
    DocumentTextIcon,
    HomeIcon
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const { setSessionStore } = useSessionStore()
    const translations = computed(() => store.state.translations)

    const complainOptions = computed(() => {
      return {
        header: translations.value.step2_claim_heading,
        description: translations.value.step3_return_inperson_instructions,
        options: [
          {
            id: 1,
            name: translations.value.step2_claim_online_heading,
            description: translations.value.step2_claim_online_tooltip,
            icon: DocumentTextIcon,
            pathName: 'OrderSearch',
            color: 'btn-light',
            buttonText: translations.value.root_fill_form,
            requestType: 'complain'
          },
          {
            id: 2,
            name: translations.value.step2_return_inperson_heading,
            description: translations.value.step2_return_inperson_tooltip,
            icon: HomeIcon,
            pathName: '',
            color: 'btn-light',
            buttonText: translations.value.root_process,
            requestType: 'complain'
          }
        ],
        inpersonGuide: { heading: translations.value.step2_claim_inperson_heading, steps: translations.value.step3_claim_inperson_instructions}
      }
    })
    const returnOptions = computed(() => {
      return {
        header: translations.value.step2_return_heading,
        description: translations.value.step2_return_tooltip,
        options: [
          {
            id: 1,
            name: translations.value.step2_return_online_heading,
            description: translations.value.step2_return_online_tooltip,
            icon: DocumentTextIcon,
            pathName: 'OrderSearch',
            color: 'btn-light',
            buttonText: translations.value.root_fill_form,
            requestType: 'return'
          },
          {
            id: 2,
            name: translations.value.step2_return_inperson_heading,
            description: translations.value.step2_return_inperson_tooltip,
            icon: HomeIcon,
            pathName: '',
            color: 'btn-light',
            buttonText: translations.value.root_process,
            requestType: 'return'
          }
        ],
        inpersonGuide: { heading: translations.value.step2_return_inperson_heading, steps: translations.value.step3_return_inperson_instructions}
      }
    })

    //vrácení nebo reklamace
    const pathName = route.params.slug

    const selectedOption = computed(() => {
      return pathName === 'vraceni'
        ? returnOptions.value
        : complainOptions.value
    })

    // component - Vrácení/Reklamace na prodejně
    const isReturnSteps = ref(false)

    const handleClick = (pathName, request) => {
      if (pathName == 'OrderSearch') {
        // smazaní čísla objednávky a emailu z předešlého hledání
        if (sessionStorage.getItem('order_keys')) {
          sessionStorage.removeItem('order_keys')
        }

        isReturnSteps.value = false
        store.dispatch('setTypeOfRequest', request)
        //uložení typu požadavku (reklamace, vrácení) do session store
        setSessionStore('typeOfRequest', request)
        router.push({ name: pathName })
      } else {
        isReturnSteps.value = !isReturnSteps.value
      }
    }
    return { selectedOption, handleClick, isReturnSteps, translations }
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
