import { defineRule } from "vee-validate";
import { integer, max_value, required } from "@vee-validate/rules";

defineRule("quantity", (value, [maxQ]) => {
  const numericValue = Number(value);
  if (
    integer(numericValue) &&
    numericValue > 0 &&
    max_value(value, [maxQ]) &&
    required(value)
  ) {
    return true;
  }
  return `Min 1, Max: ${maxQ}`;
});
