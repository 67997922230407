<template>
  <h3 class="text-lg text-th-secondary-400 font-bold">
    <slot name="heading"></slot>
  </h3>
  <HomeIcon class="w-10 text-th-primary-200 mt-2"/>
  <div class="mt-2 space-y-2 text-th-secondary-200 text-sm">
    <slot name="steps"></slot>
  </div>
</template>

<script>
import { HomeIcon } from "@heroicons/vue/outline";

export default {
  name: "RequestOptionsInpresonGuide",
  components: { HomeIcon }
}
</script>

<style scoped>

</style>