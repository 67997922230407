<template>
  <div>
    <label :for="name" :class="[ hiddenLabel ? 'sr-only' : '', 'text-sm font-medium text-gray-700']">{{ label }}<sup v-if="isRequired" class="inline text-yellow-600">*</sup></label>
    <input
      :name="name"
      :id="name"
      :type="type"
      :value="modelValue"
      :placeholder="placeholder"
      :autocomplete="autocomplete"
      @input="handleChange"
      @blur="handleBlur"
      :class="{ 'border border-red-500': !!errorMessage && showErrorColor }"
    />

    <p class="text-xs text-red-400 mt-1 truncate" v-show="errorMessage && meta.touched">
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
import { useField } from "vee-validate";
import { watch } from "vue";

export default {
  props: {
    type: {
      type: String,
      default: "text",
    },
    modelValue: {
      type: null,
    },
    name: {
      type: String,
    },
    label: {
      type: String,
      required: true
    },
    hiddenLabel: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: "",
    },
    isRequired: {
      type: Boolean,
      default: true
    },
    autocomplete: {
      type: String,
      default: "",
    },
    showErrorColor: {
      type: Boolean,
      default: true
    },
    rules: {
      default: undefined
    }
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    // we don't provide any rules here because we are using form-level validation
    // https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
    const {
      value,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(props.name, props.rules, {
      initialValue: props.modelValue,
    });

    // Sync v-model binding with vee-validate model changes
    watch(value, (newValue) => {
      if (newValue !== props.modelValue) {
        emit("update:modelValue", newValue);
      }
    });

    // Sync vee-validate's model with external model changes.
    watch(
      () => props.modelValue,
      (newModel) => {
        if (newModel !== value.value) {
          value.value = newModel;
        }
      }
    );

    return {
      handleChange,
      handleBlur,
      errorMessage,
      meta,
      value
    };
  },
};
</script>