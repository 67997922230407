import apiClient from "./apiClient";
import store from "../store";
import { ref } from "vue";

const createClaim = () => {
  const error = ref(null);

  const postData = async (url, data, files) => {
    error.value = null;
    try {
      const response = await apiClient({
        "Content-Type": "application/json",
      }).post(url, data);

      if (response.data.pdf_summary_url) {
        store.dispatch("setFormPdfUrl", response.data.pdf_summary_url);
      }

      /* soubory */
      if (store.state.claim.attachments.length) {
        await apiClient({
          "Content-Type": "multipart/form-data",
        }).post(`claim_files/${response.data.id}`, files);
      }
    } catch (err) {
      error.value = "Zaslání požadavku se nezdařilo, zkuste to prosím později.";
    }
  };

  return {
    postData,
    error,
  };
};

export default createClaim;
