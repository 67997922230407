<template>
  <div v-if="loading">
    <div class="fixed flex flex-col inset-0 gap-3 items-center justify-center p-2 text-gray-700">
      <h1 class="font-bold text-2xl">Online formulář</h1>
      <div class="flex gap-1.5 justify-center items-center text-gray-400">
        <LoadingIcon/>
        <p class="text-base">Načítá se...</p>
      </div>
    </div>
  </div>
  <ErrorInitialLoad v-else-if="error"/>
  <div v-else>
    <div :class="theme ? theme : 'default'">
    <!--<div class="default">-->
      <TheNavbar/>
      <div class="m-2 sm:m-8">
        <router-view />
      </div>
    </div>
  </div>
</template>

<style></style>
<script>
import TheNavbar from "./components/TheNavbar";
import ErrorInitialLoad from "./components/ErrorInitialLoad";
import getFormSettings from "./services/getFormSettings";
import { useStore } from "vuex";
import { computed, ref } from "vue";
import LoadingIcon from "./components/LoadingIcon";

export default {
  components: { LoadingIcon, ErrorInitialLoad, TheNavbar },
  setup() {
    const store = useStore()
    const { fetchSetting, fetchTranslations, fetchReturnReasons } = getFormSettings()
    const htmlLanguage = document.documentElement.lang
    const loading = ref(false)
    const error = ref(null)

    //stažení nastavení a překladů formuláře
    const setSettings = async () => {
      loading.value = true
      try {
        let [settings, translations, returnReasons] = await Promise.all([
          fetchSetting(store.state.project_id),
          fetchTranslations({ lang: htmlLanguage }),
          fetchReturnReasons({ lang: htmlLanguage, project_id: store.state.project_id })
        ])
        store.dispatch('setFormSettings', { settings, translations, returnReasons })
      } catch (e) {
        error.value = e.message
      } finally {
        loading.value = false
      }
    }
    setSettings()

    //theme setting
    const color = computed(() => store.state.settings.form_color)
    const cornerStyle = computed(() => store.state.settings.form_style)
    const theme = computed(() => {
      return color.value + ' ' + (cornerStyle.value === 'border-rounded' ? '' : 'border-squared')
    })

    return { loading, theme, error }
  },
};


</script>

<style>
.border-squared {
  --form-style: 0;
}
</style>
