import { createStore } from "vuex";

const getDefaultClaim = () => {
  return {
    claim_type: null,
    order_id: null,
    invoice_id: null,
    email: null,
    msg: null,
    items: [],
    attachments: [],
    claimant: {},
    customer: {},
  };
};
const getDefaultForm = () => {
  return {
    isDeliveryAddress: false,
    isCompanyAddress: false,
    order: { items: [] },
    typeOfRequest: "",
    isManualFill: false,
  };
};

export default createStore({
  state: {
    project_id: 'pr_ah62b493c8aab27',
    claim: getDefaultClaim(),
    form: getDefaultForm(),
    formSteps: [],
    settings: {
      company_name: null,
      company_logo: null,
      form_style: "border-rounded",
      form_color: "default",
      langs: [],
      delivery_address: null,
      claim_solutions: [],
      return_reasons: [],
      privacy_policy_url: null,
      terms_of_trade_url: null,
    },
    currentLang: 'cs',
    returnReasons: {},
    translations: {},
    pdfUrl: null,
  },
  getters: {
    getProjectId: (store) => {
      return store.state.project_id;
    },
    getReturnReasons: (store) => {
      return store.returnReasons;
    },
    getAttachmentsNames: (store) => {
      return store.claim.attachments.map((file) => file.name);
    },
    getFinalMessage: (store) => {
      return store.form.finalMessage;
    },
    getIsManualFill: (store) => {
      return store.form.isManualFill;
    },
    getLangs: (store) => {
      return store.settings.langs;
    },
    getCurrentLang: (store) => {
      return store.selectedLang;
    },
    getOrderDate: (store) => {
      let date = new Date(store.form.order.date);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let dt = date.getDate();

      if (dt < 10) {
        dt = "0" + dt;
      }
      if (month < 10) {
        month = "0" + month;
      }

      return dt + "." + month + "." + year;
    },
    getReturnedProducts: (store) => {
      return store.form.order.items.filter(
        (product) => product.requestData.isReturning === true
      );
    },
    getTranslations: (store) => {
      return store.translations;
    },
    isProductSelected: (store) => {
      return store.form.order.items.some(
        (product) => product.requestData.isReturning === true
      );
    },
  },
  mutations: {
    ADD_ATTACHMENT(state, file) {
      state.claim.attachments.push(file);
    },
    REMOVE_ATTACHMENT(state, file) {
      state.claim.attachments = state.claim.attachments.filter(
        (attachment) => attachment !== file
      );
    },
    RESET_FORM(state) {
      Object.assign(state.claim, getDefaultClaim());
      Object.assign(state.form, getDefaultForm());
    },
    RESET_FORM_BEFORE_MANUAL_FILL(state) {
      Object.assign(state.claim, {
        order_id: null,
        invoice_id: null,
        msg: null,
        items: [],
        attachments: [],
        claimant: {
          email: null,
          phone: null,
          purchaser_id: null,
          billing_firstname: null,
          billing_lastname: null,
          billing_address: null,
          billing_psc: null,
          billing_city: null,
          billing_country: null,
          billing_ic: null,
          billing_dic: null,
          billing_company: null,
          delivery_firstname: null,
          delivery_lastname: null,
          delivery_address: null,
          delivery_psc: null,
          delivery_city: null,
          delivery_country: null,
          delivery_company: null,
        },
      });
      Object.assign(state.form, {
        isDeliveryAddress: false,
        isCompanyAddress: false,
        order: { items: [] },
      });
    },
    SET_CLAIM_ITEMS(state, payload) {
      // prevence před duplicitními hodnotami
      state.claim.items = [];
      //automatické vyplnění formuláře
      if (payload.formType === "auto") {
        let returnedProducts = state.form.order.items.filter(
          (item) => item.requestData.isReturning === true
        );
        returnedProducts.forEach((product) => {
          state.claim.items.push({
            code: product.code,
            reason: product.requestData.reasonOfComplain,
            claim_solution_id: product.requestData.requestSolution
              ? product.requestData.requestSolution.id
              : null,
            return_reason_id: product.requestData.reasonOfReturning
              ? product.requestData.reasonOfReturning.id
              : null,
            quantity: product.requestData.quantityReturn,
          });
        });
      } else {
        //manuální vyplnění formuláře

        state.claim.items = payload.items;
      }
    },
    SET_ORDER(state, orderData) {
      state.claim.order_id = orderData.order_no;
      state.claim.email = orderData.customer.email;
      // nutný reset z důvodu propojení hodnoty také s Manuálním frmulářem (2. krok FormManualFill)
      state.claim.items = [];
      //předvyplnění údajů pro FormContactInfo component (3.krok formuláře)
      state.claim.claimant = {
        ...orderData.customer,
        //úprava formátu billing_country pro select input
        billing_country: {name: orderData.customer.billing_country},
        delivery_firstname: null,
        delivery_lastname: null,
        delivery_address: null,
        delivery_psc: null,
        delivery_city: null,
        delivery_country: null,
        delivery_company: null,
        purchaser_id: null,
      };
      //zachování původních údajů o zákazníkovi z objednávky
      state.claim.customer = {
        firstname: orderData.customer.billing_firstname,
        lastname: orderData.customer.billing_lastname,
        email: orderData.customer.email,
        phone: orderData.customer.phone,
        ic: orderData.customer.billing_ic,
        dic: orderData.customer.billing_dic,
        company: orderData.customer.billing_company,
      };

      //přidání properties k jedlotlivých produktům objednávky
      const extendedOrderData = orderData;
      extendedOrderData.items.forEach((item) => {
        item.requestData = {
          isReturning: false,
          quantityReturn: 1,
          reasonOfReturning: null,
          reasonOfComplain: "",
          requestSolution: null,
        };
      });

      state.form.order = extendedOrderData;

      if(orderData.customer.billing_ic || orderData.customer.billing_dic) {
        state.form.isCompanyAddress = true
      }
    },
    SET_FORM_SETTINGS(state, settings) {
      state.settings = settings;
    },
    SET_CURRENT_LANG(state, lang) {
      state.currentLang = lang;
    },
    SET_TRANSLATIONS(state, translations) {
      state.translations = translations;
    },
    SET_RETURN_REASONS(state, returnReasons) {
      state.returnReasons = returnReasons;
    },
    SET_TYPE_OF_REQUEST(state, request) {
      state.form.typeOfRequest = request;
      state.claim.claim_type = request === "complain" ? 1 : 2;
    },
    SET_FORM_PDF_URL(state, url) {
      state.pdfUrl = url;
    },
    UPDATE_FORM_DATA(state, payload) {
      state.form[payload.key] = payload.value;
    },
    UPDATE_CLAIM_DATA(state, payload) {
      state.claim[payload.key] = payload.value;
    },
  },
  actions: {
    addAttachment({ commit }, file) {
      commit("ADD_ATTACHMENT", file);
    },
    removeAttachment({ commit }, file) {
      commit("REMOVE_ATTACHMENT", file);
    },
    resetForm({ commit }, payload) {
      commit("RESET_FORM", payload);
    },
    resetFormBeforeManualFill({ commit }) {
      commit("RESET_FORM_BEFORE_MANUAL_FILL");
    },
    setClaimItems({ commit }, payload) {
      commit("SET_CLAIM_ITEMS", payload);
    },
    setOrder({ commit }, data) {
      commit("SET_ORDER", data);
    },
    setFormSettings({ commit }, payload) {
      commit("SET_FORM_SETTINGS", payload.settings);
      commit("SET_TRANSLATIONS", payload.translations);
      commit("SET_RETURN_REASONS", payload.returnReasons);
    },
    setTranslations({ commit }, payload) {
      commit("SET_TRANSLATIONS", payload);
    },
    setReturnReasons({ commit }, payload) {
      commit("SET_RETURN_REASONS", payload);
    },
    setCurrentLang({ commit }, payload) {
      commit("SET_CURRENT_LANG", payload);
    },
    setTypeOfRequest({ commit }, request) {
      commit("SET_TYPE_OF_REQUEST", request);
    },
    setFormPdfUrl({ commit }, url) {
      commit("SET_FORM_PDF_URL", url);
    },
    updateFormData({ commit }, payload) {
      commit("UPDATE_FORM_DATA", payload);
    },
    updateClaimData({ commit }, payload) {
      commit("UPDATE_CLAIM_DATA", payload);
    },
  },
  modules: {},
});
