const useSessionStore = () => {

  const setSessionStore = (key, parsedData) => {
    sessionStorage.setItem(key, parsedData)
  }

  const getSessionStore = (key) => {

    if (sessionStorage.getItem(key)) {
      try {
        let data = JSON.parse(sessionStorage.getItem(key));
        return data
      }catch (e){
        sessionStorage.removeItem(key);
      }
    }
  }

  const removeSessionStore = (key) => {
    sessionStorage.removeItem(key);
  }

  return { setSessionStore, getSessionStore, removeSessionStore }
}

export default useSessionStore
