<template>
  <div class="text-base">
    <router-link
      :to="{ name: pathName, params: params }"
      class="
        transition
        px-4
        py-2
        border border-transparent
        font-medium
        border-radious_custom
        shadow-sm
        focus:outline-none focus:ring-1 focus:ring-offset-1
        text-th-secondary-400
        border-th-primary-200
        hover:border-th-primary-200
        hover:text-th-tertiary-100
        hover:bg-th-primary-200
        focus:ring-th-primary-200
      "
    >
      <slot>Pokračovat</slot>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'BaseButtonPrimary',
  props: {
    pathName: {
      type: String
    },
    params: {
      type: Object,
      default: null
    }
  }
}
</script>

<style scoped></style>
