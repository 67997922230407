<template>
  <div
    @dragenter.prevent="toggleActive"
    @dragleave.prevent="toggleActive"
    @dragover.prevent
    @drop.prevent="toggleActive"
    :class="{ 'active-dropzone': isActive }"
    class="
      flex flex-col
      items-start
      gap-y-0.5
      text-sm
      w-full
    "
  >
    <label
      for="dropzoneFile"
      class="
        active
        flex
        items-center
        bg-white
        py-2
        px-3
        border border-gray-300
        border-radious_custom
        shadow-sm
        text-sm
        leading-4
        font-medium
        text-gray-700
        hover:bg-gray-50
        focus:outline-none
        focus:ring-2
        focus:ring-offset-2
        focus:ring-indigo-500
        cursor-pointer
      "
    >
      <span>{{ translations.form_step_2_add_attachment }}</span>
      <PaperClipIcon class="h-5 w-5 text-th-primary-200 ml-2" />
    </label>
    <input
      type="file"
      multiple
      id="dropzoneFile"
      class="dropzoneFile sr-only"
    />
  </div>
</template>

<script>
import { PaperClipIcon } from '@heroicons/vue/outline'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
export default {
  name: 'BaseInputUploadFile',
  components: { PaperClipIcon },
  setup() {
    const store = useStore()
    const isActive = ref(false)
    const translations = computed(() => store.state.translations)

    const toggleActive = () => {
      isActive.value = !isActive.value
    }

    return { isActive, toggleActive, translations }
  }
}
</script>

<style scoped></style>
