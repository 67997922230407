<template>
  <div class="relative">
    <label :for="name" class="block text-sm font-medium text-gray-700">{{ label }} <sup :class="required" class="text-th-primary-200">*</sup></label>
    <input
      :name="name"
      :id="name"
      :key="key"
      :type="type"
      :value="inputValue"
      :placeholder="placeholder"
      :autocomplete="autocomplete"
      v-on="validationListeners"
      :class="{ 'border border-red-500': !!errorMessage }"

    />
    <p class="text-xs text-red-400 mt-1" v-show="errorMessage">
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
import { useField } from "vee-validate";
import { computed } from "vue";

export default {
  name: "BaseInput",
  props: {
    type: {
      type: String,
      default: "text",
    },
    value: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    autocomplete: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    key: {
      type: Number
    },
    rules: {
      type: null,
      default: undefined,
    },
    quantity: {
      type: Number
    },
    required: {
      type: String,
      default: 'inline'
    }
  },
  setup(props){
    const { value: inputValue, errorMessage, handleChange, handleInput, meta, } = useField(props.name, props.rules, {
      initialValue: props.value,
    });



    const validationListeners = computed(() => {
      // If the field is valid or have not been validated yet
      // lazy
      if (!errorMessage.value) {
        return {
          blur: handleChange,
          change: handleChange,
          input: handleInput,
        };
      }
      // Aggressive
      return {
        blur: handleChange,
        change: handleChange,
        input: handleChange, // only switched this
      };
    });

    return {
      handleChange,
      errorMessage,
      inputValue,
      meta,
      validationListeners,
    };
  }
};
</script>

<style scoped>

</style>