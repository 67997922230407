<template>
  <!--Mobile view-->
  <div class="flex items-center justify-between">
    <h3 class="text-lg text-th-primary-200 font-semibold">
      {{ translations.form_step_2_history }}
    </h3>
    <BaseButton color="dark" class="sm:hidden text-xs" @btn-click="newSearch">{{
      translations.form_step_2_new_search
    }}</BaseButton>
  </div>
  <!--  view from 640 px-->
  <div v-if="products.length" class="space-y-5">
    <OrderSearchResults pathName="OrderSearch" @new-search="newSearch">
      {{ translations.form_step_2_new_search }}
    </OrderSearchResults>

    <!--  Produkty objednávky-->
    <div class="flow-root">
      <h4 class="text-sm font-medium text-th-primary-200 mb-3">
        {{ translations.form_step_2_order_product }}
      </h4>
      <p v-if="selectedError" class="text-xs text-red-400 mb-3">
        {{ translations.form_step_2_selected_error }}
        <span class="text-th-primary-200 font-bold">{{
          typeOfRequest === 'complain'
            ? translations.form_step_2_claim_checkbox
            : translations.form_step_2_return_checkbox
        }}</span>
        .
      </p>
      <ul
        role="list"
        class="-my-6 divide-y divide-gray-200 border-b border-gray-200"
      >
        <li
          v-for="(product, idx) in products"
          :key="product.code"
          class="py-6 flex flex-col gap-4"
        >
          <div class="flex">
            <div
              class="
                flex-shrink-0
                w-20
                h-20
                sm:w-24 sm:w-24
                border border-gray-200
                rounded-md
                overflow-hidden
              "
            >
              <img
                v-if="product.imageSrc"
                :src="product.imageSrc"
                :alt="product.imageAlt"
                class="w-full h-full object-center object-cover"
              />
              <img
                v-else
                src="@/assets/images/image-placeholder-icon.jpg"
                alt="produkt"
                class="w-full h-full object-center object-cover"
              />
            </div>

            <div class="ml-4 flex-1 flex flex-col">
              <div>
                <div
                  class="
                    flex
                    justify-between
                    text-sm
                    md:text-base
                    font-medium
                    text-gray-900
                  "
                >
                  <h4>
                    <a :href="product.href">
                      {{ product.name }}
                    </a>
                  </h4>
                  <div class="flex ml-4 flex-shrink-0">
                    <span class="ml-auto"
                      >{{ product.price }}
                      {{ translations.form_step_2_currency }}</span
                    >
                  </div>
                </div>
                <p class="mt-1 text-sm text-gray-500">
                  {{ product.attribute }}
                </p>
              </div>
              <div class="flex-1 flex items-end justify-between text-sm">
                <p class="text-gray-500">
                  {{ translations.form_step_2_pcs }}
                  {{ parseInt(product.quantity) }}
                </p>
                <div class="flex relative items-center">
                  <input
                    id="return"
                    aria-describedby="return"
                    name="return"
                    type="checkbox"
                    v-model="product.requestData.isReturning"
                    class="
                      mt-0
                      focus:ring-th-primary-200
                      h-4
                      w-4
                      text-th-primary-200
                      border-gray-300
                      rounded
                    "
                  />
                  <div class="ml-3 text-sm">
                    <label for="return" class="font-medium text-th-primary-200">
                      {{
                        typeOfRequest === 'complain'
                          ? translations.form_step_2_claim_checkbox
                          : translations.form_step_2_return_checkbox
                      }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--informace o vrácení / reklamaci od zákazníka-->
          <transition name="return">
            <div v-if="product.requestData.isReturning">
              <form>
                <!--Vrácení produktu-->
                <div
                  v-if="typeOfRequest === 'return'"
                  class="flex flex-wrap gap-2"
                >
                  <div class="min-w-5">
                    <BaseSelectInput
                      :options="returnReasons"
                      :name="'reason' + idx"
                      v-model="product.requestData.reasonOfReturning"
                      key-prop="name"
                      label-prop="name"
                      :label="translations.form_step_2_return_reason"
                      :rules="validateField"
                    />
                  </div>
                  <div>
                    <div class="w-16">
                      <label
                        :for="'quantity' + idx"
                        class="text-sm font-medium text-gray-700"
                        >{{ translations.form_step_2_quantity }}</label
                      >
                      <Field
                        type="number"
                        :name="'quantity' + idx"
                        :rules="'quantity:' + parseInt(product.quantity)"
                        v-model="product.requestData.quantityReturn"
                        class="text-sm font-medium text-gray-700 text-center"
                      />
                    </div>
                    <ErrorMessage
                      :name="'quantity' + idx"
                      class="text-xs text-red-400 mt-1"
                    />
                  </div>
                </div>
                <!--              Reklamace produktu-->
                <div v-else class="flex flex-col">
                  <div class="w-full">
                    <div>
                      <label
                        :for="'reason' + idx"
                        class="block text-sm font-medium text-gray-700"
                        >{{ translations.form_step_2_claim_reason }}
                      </label>
                      <Field
                        as="textarea"
                        :placeholder="
                          translations.form_step_2_claim_reason_placeholder
                        "
                        :name="'reason' + idx"
                        :rules="reasonRules"
                        v-model="product.requestData.reasonOfComplain"
                      />
                    </div>
                    <ErrorMessage
                      :name="'reason' + idx"
                      class="text-xs text-red-400 mt-1"
                    />
                  </div>

                  <div class="flex flex-wrap gap-2 mt-3">
                    <div v-if="showRequestSolution" class="min-w-4">
                      <BaseSelectInput
                        :options="requestSolution"
                        :name="'request' + idx"
                        v-model="product.requestData.requestSolution"
                        key-prop="name"
                        label-prop="name"
                        :label="translations.form_step_2_claim_solution"
                        :rules="validateField"
                      />
                    </div>
                    <div>
                      <div class="justify-self-end xl:justify-self-auto w-16">
                        <label
                          :for="'quantity' + idx"
                          class="text-sm font-medium text-gray-700"
                          >{{ translations.form_step_2_quantity }}
                        </label>
                        <Field
                          type="number"
                          :name="'quantity' + idx"
                          :rules="'quantity:' + parseInt(product.quantity)"
                          v-model="product.requestData.quantityReturn"
                          class="text-sm font-medium text-gray-700 text-center"
                        />
                      </div>
                      <ErrorMessage
                        :name="'quantity' + idx"
                        class="text-xs text-red-400 mt-1"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </transition>
        </li>
      </ul>
    </div>

    <!--  Přílohy-->
    <div>
      <h4 class="pt-5 text-sm font-medium text-th-primary-200">
        {{
          typeOfRequest === 'return'
            ? translations.form_step_2_attachments
            : translations.form_manual_step_2_claim_attachments
        }}
      </h4>
      <p class="mt-1 text-sm text-gray-500">
        {{
          typeOfRequest === 'return'
            ? translations.form_manual_step_2_return_attachments_desc
            : translations.form_manual_step_2_claim_attachments_desc
        }}
      </p>
      <div class="my-2" v-if="attachments">
        <ul>
          <li
            v-for="(file, index) in attachments"
            :key="index"
            class="flex items-start justify-between mb-2.5"
          >
            <div class="flex">
              <DocumentTextIcon class="h-5 w-5 text-th-primary-200" />
              <span class="text-sm break-all">{{ file.name }}</span>
            </div>
            <button
              @click="removeFile(file)"
              type="button"
              class="ml-2 text-th-primary-200 hover:text-red-700"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </li>
        </ul>
      </div>
      <div class="mt-2">
        <BaseInputUploadFile
          @drop.prevent="addDropFiles"
          @change="addSelectFiles"
        />
      </div>
    </div>

    <!--  Footer Navigation-->
    <div
      class="
        flex
        justify-between
        py-4
        px-2
        bg-th-primary-200-l
        text-right
        border-radious_custom
        text-sm
        md:text-base
      "
    >
      <button
        type="button"
        @click="newSearch"
        class="flex justify-between items-center hover:text-th-primary-200"
      >
        <ChevronLeftIcon class="h-5 w-5 text-" />
        {{ translations.root_back }}
      </button>
      <button
        type="submit"
        @click="onSubmit"
        class="flex justify-between items-center hover:text-th-primary-200"
      >
        {{ translations.root_continue }}
        <ChevronRightIcon class="h-5 w-5 text-" />
      </button>
    </div>
  </div>
  <div v-else>
    <Spinner />
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { computed, ref, watch } from 'vue'
import { useForm, Field, ErrorMessage } from 'vee-validate'
import { string } from 'yup'
import BaseInputUploadFile from '../../components/base/BaseInputUploadFile'
import BaseSelectInput from '../../components/base/BaseSelectInput'
import OrderSearchResults from '../../components/OrderSearchResults'
import Spinner from '../../components/Spinner'
import BaseButton from '../../components/base/BaseButton'
import { DocumentTextIcon } from '@heroicons/vue/outline'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/solid'

export default {
  name: 'FormOrderOverview',
  components: {
    BaseButton,
    Spinner,
    OrderSearchResults,
    BaseSelectInput,
    BaseInputUploadFile,
    DocumentTextIcon,
    Field,
    ErrorMessage,
    ChevronRightIcon,
    ChevronLeftIcon
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const selectedError = ref(false)

    const translations = computed(() => store.state.translations)
    const returnReasons = computed(() => store.getters.getReturnReasons)
    const order = computed(() => store.state.form.order)
    const products = computed(() => store.state.form.order.items)
    const typeOfRequest = computed(() => store.state.form.typeOfRequest)
    const attachments = computed(() => store.state.claim.attachments)
    const requestSolution = computed(() => store.state.settings.claim_solutions)
    const showRequestSolution = computed(() => store.state.settings.show_claim_solutions)

    //Nové hledání objednávky
    const newSearch = () => {
      sessionStorage.removeItem('order_keys')
      router.push({ name: 'OrderSearch' })
    }

    //validace pole: Důvod Reklamace
    const { handleSubmit } = useForm({})
    const reasonRules = string()
      .trim()
      .required(translations.value.root_error_required)
      .label(translations.value.form_step_2_claim_reason)

    function validateField(value) {
      if (!value) {
        return translations.value.root_error_required
      }
      return true
    }

    //kontrola, zda alespon jeden produkt označen k vrácení / reklamaci
    const isSelected = computed(() => store.getters.isProductSelected)

    watch(isSelected, () => {
      selectedError.value = !isSelected.value
    })

    //navigace
    const onSubmit = handleSubmit(() => {
      if (isSelected.value) {
        //payload items tady prázdné, naplní se pouze u manuálního formuláře
        store.dispatch('setClaimItems', { formType: 'auto', items: [] })
        router.push({ name: 'FormContactInfo' })
      } else {
        selectedError.value = true
      }
    })

    /*drop and drag*/

    // const fileSelectError = false
    const filesNames = computed(() => store.getters.getAttachmentsNames)

    const addDropFiles = (e) => {
      const droppedFiles = e.dataTransfer.files

      if (!droppedFiles) return
      let file
      for (let i = 0; i < droppedFiles.length; i++) {
        file = droppedFiles[i]
        if (!filesNames.value.includes(file.name)) {
          // dropzoneFiles.value.push(file)
          store.dispatch('addAttachment', file)
        }
      }
    }
    /*drop and drag*/

    const addSelectFiles = () => {
      let selectedFiles = document.querySelector('.dropzoneFile').files
      let file
      for (let i = 0; i < selectedFiles.length; i++) {
        file = selectedFiles[i]
        if (!filesNames.value.includes(file.name)) {
          store.dispatch('addAttachment', file)
        }
      }
    }

    const removeFile = (file) => {
      store.dispatch('removeAttachment', file)
    }

    return {
      newSearch,
      order,
      attachments,
      requestSolution,
      typeOfRequest,
      products,
      addDropFiles,
      addSelectFiles,
      removeFile,
      returnReasons,
      validateField,
      onSubmit,
      selectedError,
      reasonRules,
      translations,
      showRequestSolution
    }
  }
}
</script>

<style scoped>
.return-enter-active,
.return-leave-active {
  transition: opacity 0.3s ease;
}

.return-enter-from,
.return-leave-to {
  opacity: 0;
}
</style>
