import { createRouter, createWebHashHistory } from "vue-router";
import store from "../store/index.js";
import Home from "../views/Home.vue";
import OrderSearch from "../views/form/OrderSearch";
import FormContactInfo from "../views/form/FormContactInfo";
import FormSummary from "../views/form/FormSummary";
import OnlineForm from "../views/OnlineForm";
import FormOrderOverview from "../views/form/FormOrderOverview";
import RequestOptions from "../views/RequestOptions";
import FormClosure from "../views/form/FormClosure";
import FormManualFill from "../views/form/FormManualFill";

const requireTypeOfRequest = (to, from, next) => {
  if (sessionStorage.getItem("typeOfRequest")) {
    next();
  } else {
    next({ name: "Home" });
  }
};

// nastavení navigace formuláře
const setTypeFormSteps = async (to, from, next) => {
  if (sessionStorage.getItem("typeOfRequest")) {
    if (from.name === "OrderSearch" || from.name === undefined) {
      let isFormManualFill = to.name === "FormManualFill";
      await store.dispatch("updateFormData", {
        key: "isManualFill",
        value: isFormManualFill,
      });
      if (isFormManualFill) await store.dispatch("resetFormBeforeManualFill");
    }
    next();
  } else {
    next({ name: "Home" });
  }
};

const requireOrder = (to, from, next) => {
  if (store.state.form.isManualFill) {
    next();
  } else {
    const isSelected = store.getters.isProductSelected;
    let path;
    if (
      sessionStorage.getItem("typeOfRequest") &&
      sessionStorage.getItem("order_keys")
    ) {
      try {
        const request = sessionStorage.getItem("typeOfRequest");
        path = `/vraceni-a-reklamace/${
          request === "return" ? "vraceni" : "reklamace"
        }/online/prehled-objednavky`;
      } catch (e) {
        localStorage.removeItem("typeOfRequest");
      }
    }
    //na refresh stránky přesměrovat na 1.krok formuláře (FormOrderOverview), pokud máme č.obj a email
    if (!isSelected && path) {
      next(path);
    } else if (!isSelected && !path) {
      next({ name: "Home" });
    } else {
      next();
    }
  }
};

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/vraceni-a-reklamace/:slug",
    name: "RequestOptions",
    component: RequestOptions,
  },
  {
    path: "/vraceni-a-reklamace/:slug/online",
    name: "OnlineForm",
    component: OnlineForm,
    children: [
      {
        path: "vyhledani-objednavky",
        name: "OrderSearch",
        component: OrderSearch,
        beforeEnter: requireTypeOfRequest,
      },
      {
        path: "prehled-objednavky",
        name: "FormOrderOverview",
        component: FormOrderOverview,
        beforeEnter: setTypeFormSteps,
      },
      {
        path: "prehled-objednavky-manualne",
        name: "FormManualFill",
        component: FormManualFill,
        beforeEnter: setTypeFormSteps,
      },
      {
        path: "kontaktni-udaje",
        name: "FormContactInfo",
        component: FormContactInfo,
        beforeEnter: requireOrder,
      },
      {
        path: "souhrn",
        name: "FormSummary",
        component: FormSummary,
        beforeEnter: requireOrder,
      },
      {
        path: "zaver/:status",
        name: "FormClosure",
        component: FormClosure,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
