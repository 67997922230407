<template>
  <nav aria-label="Progress">
    <!--Navigace mobile view-->
    <div v-if="currentStep" class="mb-8 pt-2 lg:hidden">
      <div class="flex">
        <h3 class="font-semibold text-th-secondary-400">
          {{ translations.root_step }} {{ currentStep.id }}
          {{ translations.root_of }} {{ formSteps.length }}
        </h3>
        <ol role="list" class="ml-8 flex items-center space-x-5">
          <li v-for="step in formSteps" :key="step.id">
            <a
              v-if="step.status === 'complete'"
              :href="step.href"
              class="block w-2.5 h-2.5 bg-th-primary-200 rounded-full"
            >
              <span class="sr-only">{{ step.name }}</span>
            </a>
            <a
              v-else-if="step.status === 'current'"
              :href="step.href"
              class="relative flex items-center justify-center"
              aria-current="step"
            >
              <span class="absolute w-5 h-5 p-px flex" aria-hidden="true">
                <span class="w-full h-full rounded-full bg-gray-300" />
              </span>
              <span
                class="
                  relative
                  block
                  w-2.5
                  h-2.5
                  bg-th-primary-200
                  rounded-full
                "
                aria-hidden="true"
              />
              <span class="sr-only">{{ step.name }}</span>
            </a>
            <a
              v-else
              :href="step.href"
              class="
                block
                w-2.5
                h-2.5
                bg-gray-400
                rounded-full
                hover:bg-gray-400
              "
            >
              <span class="sr-only">{{ step.name }}</span>
            </a>
          </li>
        </ol>
      </div>
      <p class="text-sm text-th-secondary-200 mt-2">
        {{ currentStep.instructions }}
      </p>
    </div>

    <!--Navigace from 768px-->
    <div class="hidden lg:block">
      <div v-if="currentStep" class="mb-8">
        <h3 class="text-lg font-semibold text-th-secondary-400">
          {{ translations.root_step }} {{ currentStep.id }}
          {{ translations.root_of }} {{ formSteps.length }}
        </h3>
        <p class="mt-1.5 text-sm text-th-secondary-200">
          {{ currentStep.instructions }}
        </p>
      </div>
      <!--   Step navigation -->
      <ol role="list" class="overflow-hidden">
        <li
          v-for="(step, stepIdx) in formSteps"
          :key="step.id"
          :class="[stepIdx !== formSteps.length - 1 ? 'pb-10' : '', 'relative']"
        >
          <!--        Satus complete-->
          <template v-if="step.status === 'complete'">
            <div
              v-if="stepIdx !== formSteps.length - 1"
              class="
                -ml-px
                absolute
                mt-0.5
                top-4
                left-4
                w-0.5
                h-full
                bg-th-primary-200
              "
              aria-hidden="true"
            />
            <div class="relative flex items-center group">
              <span class="h-9 flex items-center">
                <span
                  class="
                    relative
                    z-10
                    w-8
                    h-8
                    flex
                    items-center
                    justify-center
                    bg-th-primary-200
                    rounded-full
                  "
                >
                  <span class="text-th-tertiary-100">{{ step.id }}</span>
                </span>
              </span>
              <span class="ml-4 min-w-0 flex flex-col">
                <span class="text-xs font-semibold tracking-wide uppercase">{{
                  step.name
                }}</span>
              </span>
            </div>
          </template>
          <!--        Status current-->
          <template v-if="step.status === 'current'">
            <div
              v-if="stepIdx !== formSteps.length - 1"
              class="
                -ml-px
                absolute
                mt-0.5
                top-4
                left-4
                w-0.5
                h-full
                bg-gray-300
              "
              aria-hidden="true"
            />
            <div class="relative flex items-center group" aria-current="step">
              <span class="h-9 flex items-center" aria-hidden="true">
                <span
                  class="
                    relative
                    z-10
                    w-8
                    h-8
                    flex
                    items-center
                    justify-center
                    bg-white
                    border-2 border-th-primary-200
                    rounded-full
                  "
                >
                  <span class="text-th-primary-200 text-semibold">{{
                    step.id
                  }}</span>
                </span>
              </span>
              <span class="ml-4 min-w-0 flex flex-col">
                <span
                  class="
                    text-xs
                    font-bold
                    tracking-wide
                    uppercase
                    text-th-primary-200
                  "
                  >{{ step.name }}</span
                >
              </span>
            </div>
          </template>
          <!--        Status upcoming-->
          <template
            v-if="step.status === 'upcoming' && $route.path !== step.path"
          >
            <div
              v-if="stepIdx !== formSteps.length - 1"
              class="
                -ml-px
                absolute
                mt-0.5
                top-4
                left-4
                w-0.5
                h-full
                bg-gray-300
              "
              aria-hidden="true"
            />
            <div class="relative flex items-center group">
              <span class="h-9 flex items-center" aria-hidden="true">
                <span
                  class="
                    relative
                    z-10
                    w-8
                    h-8
                    flex
                    items-center
                    justify-center
                    bg-white
                    border-2 border-gray-300
                    rounded-full
                  "
                >
                  <span class="text-gray-300 text-semibold">{{ step.id }}</span>
                </span>
              </span>
              <span class="ml-4 min-w-0 flex flex-col">
                <span
                  class="
                    text-xs
                    font-semibold
                    tracking-wide
                    uppercase
                    text-gray-500
                  "
                  >{{ step.name }}
                </span>
              </span>
            </div>
          </template>
        </li>
      </ol>
    </div>
  </nav>
</template>

<script>
import { computed, ref, watchEffect } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

export default {
  name: 'FormStepsNavbar',
  setup() {
    const route = useRoute()
    const store = useStore()
    const translations = computed(() => store.getters.getTranslations)
    const isManualFill = computed(() => store.getters.getIsManualFill)

    const formSteps = computed(() => {
      return [
        {
          id: 1,
          name: translations.value.form_step_1_name,
          instructions: translations.value.form_step_1_instructions,
          pathName: 'OrderSearch',
          status: 'current',
          path: '/vyhledani-objednavky'
        },
        {
          id: 2,
          name: translations.value.form_step_2_name,
          instructions: isManualFill.value
            ? translations.value.form_manual_step_2_instructions
            : translations.value.form_step_2_instructions,
          pathName: isManualFill.value
            ? 'FormManualFill'
            : 'FormOrderOverview',
          status: 'upcoming',
          path: isManualFill.value
            ? '/prehled-objednavky-manualne'
            : '/prehled-objednavky'
        },
        {
          id: 3,
          name: translations.value.form_step_3_name,
          instructions: translations.value.form_step_3_instructions,
          pathName: 'FormContactInfo',
          status: 'upcoming',
          path: '/kontaktni-udaje'
        },
        {
          id: 4,
          name: translations.value.form_step_4_name,
          instructions: translations.value.form_step_4_instructions,
          pathName: 'FormSummary',
          status: 'upcoming',
          path: '/souhrn'
        }
      ]
    })
    const currentStep = ref({
      id: 1,
      name: translations.value.form_step_1_name,
      instructions: translations.value.form_step_1_instructions,
      pathName: 'OrderSearch',
      status: 'current',
      path: '/vyhledani-objednavky'
    },)

    const setFormStepsStatus = (currentIdx) => {
      formSteps.value.forEach((step, idx) => {
        if (idx < currentIdx) {
          step.status = 'complete'
        } else if (idx === currentIdx) {
          step.status = 'current'
        } else {
          step.status = 'upcoming'
        }
      })
    }
    const setAllFormStepsComplete = () => {
      formSteps.value.forEach((step) => (step.status = "complete"));
    }

    watchEffect(() => {
      currentStep.value = formSteps.value.find(
        (step) => step.pathName === route.name
      )
      if (currentStep.value) {
        const currentIdx = currentStep.value.id - 1
        setFormStepsStatus(currentIdx)
      }
      if (route.name === 'FormClosure') {
        setAllFormStepsComplete()
      }
    })

    return { formSteps, currentStep, translations }
  }
}
</script>
