<template>
  <div class="text-center px-3">
    <h2 class="text-xl text-th-secondary-400 font-bold mb-5">
      {{ option.name }}
    </h2>
    <p class="text-sm text-th-secondary-200 mb-3">
      {{ option.description }}
    </p>
  </div>
  <div class="mx-auto flex flex-col gap-5">
    <slot name="icon"></slot>
    <slot name="button"></slot>
  </div>
</template>

<script>

export default {
  name: "BaseCardOption",
  components: {},
  props: {
    option: {
      type: Object
    }
  }
};
</script>

<style scoped>

</style>