<template>
  <!--  Výsledky hledání-->
  <div v-if="order.items.length" class="grid grid-cols-3 sm:grid-cols-4 gap-y-3 p-2 text-sm font-medium border border-gray-300 border-radious_custom shadow-sm ">
    <div class="grid grid-cols-1">
      <span class="col-span-2 text-gray-500">{{ translations.form_step_2_number }}</span>
      <span class="text-th-secondary-400">{{ order.order_no }}</span>
    </div>
    <div class="grid grid-cols-1">
      <span class="col-span-2 text-gray-500">{{ translations.form_step_2_date }}</span>
      <span class="text-th-secondary-400">{{ orderDate }}</span>
    </div>
    <div class="grid grid-cols-1">
      <span class="col-span-2 text-gray-500">{{ translations.form_step_2_total }}</span>
      <span class="text-th-secondary-400">{{ parseInt(order.price)}} {{ translations.form_step_2_currency }}</span>
    </div>
    <div class="hidden sm:block col-start-2 sm:col-start-4 justify-self-center sm:justify-self-end self-center">
      <BaseButton color="dark" class="text-xs" @btn-click="$emit('newSearch')">{{ translations.form_step_2_new_search}}</BaseButton>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { computed } from "vue";
import { useStore } from "vuex";
import BaseButton from "./base/BaseButton";
export default {
  name: "OrderSearchResults",
  components: { BaseButton },
  props: {
    pathName: {
      type: String,
      required: true
    },
    isDisabled: {
      type: Boolean,
      default: true
    }
  },
  emits: ['newSearch'],
  setup() {
    const store = useStore()
    const router = useRouter()

    const translations = computed(() => store.state.translations)
    const order = computed(() => store.state.form.order)
    const orderDate = computed(() => store.getters.getOrderDate)

    const newSearch = () => {
      router.push({name: 'OrderSearch'})
    }
    return { orderDate, order, newSearch, translations}
  },

};
</script>

<style scoped>

</style>