<template>
  <h3 class="text-lg text-th-primary-200 font-semibold">
    {{ translations.form_step_3_name }}
  </h3>

  <Form
    v-if="claimantAddress"
    @submit="onSubmit"
    :validation-schema="schema"
    class="grid grid-cols-6 gap-6"
  >
    <div class="col-span-6 sm:col-span-3">
      <BaseTextInput
        name="name"
        type="text"
        :label="translations.form_step_3_firstname"
        :placeholder="translations.form_step_3_firstname"
        autocomplete="name"
        v-model="claimantAddress.billing_firstname"
      />
    </div>

    <div class="col-span-6 sm:col-span-3">
      <BaseTextInput
        name="familyName"
        :label="translations.form_step_3_surname"
        :placeholder="translations.form_step_3_surname"
        autocomplete="family-name"
        v-model="claimantAddress.billing_lastname"
      />
    </div>

    <div class="col-span-6 sm:col-span-3">
      <BaseTextInput
        name="email"
        type="text"
        :label="translations.form_step_3_email"
        placeholder="abc@email.xy"
        autocomplete="email"
        v-model="claimantAddress.email"
      />
    </div>

    <div class="col-span-6 sm:col-span-3">
      <BaseTextInput
        name="phone"
        type="text"
        :label="translations.form_step_3_phone"
        :placeholder="translations.form_step_3_phone"
        autocomplete="tel"
        v-model="claimantAddress.phone"
      />
    </div>

    <div class="col-span-6 sm:col-span-3">
      <BaseTextInput
        name="streetAddress"
        type="text"
        :label="translations.form_step_3_address"
        :placeholder="translations.form_step_3_address_placeholder"
        autocomplete="billing street-address"
        v-model="claimantAddress.billing_address"
      />
    </div>

    <div class="col-span-6 sm:col-span-3">
      <BaseTextInput
        name="postalCode"
        type="number"
        :label="translations.form_step_3_psc"
        :placeholder="translations.form_step_3_psc"
        autocomplete="billing postal-code"
        v-model="claimantAddress.billing_psc"
      />
    </div>

    <div class="col-span-6 sm:col-span-3">
      <BaseTextInput
        name="city"
        type="text"
        :label="translations.form_step_3_city"
        :placeholder="translations.form_step_3_city"
        autocomplete="billing locality"
        v-model="claimantAddress.billing_city"
      />
    </div>

    <div class="col-span-6 sm:col-span-3">
      <BaseSelectInput
        :options="stateOptions"
        name="state"
        key-prop="name"
        label-prop="name"
        :label="translations.form_step_3_country"
        v-model="claimantAddress.billing_country"
      />
    </div>
    <!--Company address-->
    <div class="col-span-6 flex items-center">
      <input
        type="checkbox"
        id="companyAddress"
        name="deliveryAddress"
        class="
          mt-0
          mr-1.5
          focus:ring-th-primary-200
          h-4
          w-4
          text-th-primary-200
          border-gray-300
          rounded
        "
        v-model="isCompanyAddress"
      />
      <label for="companyAddress" class="text-sm text-gray-500">
        {{ translations.form_step_3_company_option }}
      </label>
    </div>
    <template v-if="isCompanyAddress">
      <div class="col-span-6">
        <h4 class="text-th-primary-200 font-semibold">
          {{ translations.form_step_3_company_details }}
        </h4>
      </div>

      <div class="col-span-6 sm:col-span-2">
        <BaseTextInput
          name="ic"
          type="number"
          :label="translations.form_step_3_ic"
          :isRequired="false"
          v-model="claimantAddress.billing_ic"
        />
      </div>

      <div class="col-span-6 sm:col-span-2">
        <BaseTextInput
          name="dic"
          type="text"
          :label="translations.form_step_3_dic"
          :isRequired="false"
          v-model="claimantAddress.billing_dic"
        />
      </div>

      <div class="col-span-6 sm:col-span-2">
        <BaseTextInput
          name="billingCompany"
          type="text"
          :placeholder="translations.form_step_3_company_placeholder"
          :label="translations.form_step_3_company"
          :isRequired="false"
          v-model="claimantAddress.billing_company"
        />
      </div>

      <div class="col-span-6 sm:col-span-2">
        <BaseTextInput
          name="purchaserId"
          type="text"
          :label="translations.form_step_3_purchaser_id"
          :isRequired="false"
          v-model="claimantAddress.purchaser_id"
        />
      </div>
    </template>

    <!--Delivery address-->
    <div class="col-span-6 grid gap-6">
      <div class="flex items-center">
        <input
          type="checkbox"
          id="deliveryAddress"
          name="deliveryAddress"
          class="
            mt-0
            mr-1.5
            focus:ring-th-primary-200
            h-4
            w-4
            text-th-primary-200
            border-gray-300
            rounded
          "
          v-model="isDeliveryAddress"
        />
        <label for="deliveryAddress" class="text-sm text-gray-500">
          {{ translations.form_step_3_delivery_address_option }}
        </label>
      </div>
      <!--Delivery address-->
      <div v-if="isDeliveryAddress" class="grid grid-cols-6 gap-6">
        <h4 class="col-span-6 text-th-primary-200 font-semibold">
          {{ translations.form_step_3_delivery_address }}
        </h4>
        <div class="col-span-6 sm:col-span-3">
          <BaseTextInput
            name="deliveryName"
            type="text"
            :label="translations.form_step_3_firstname"
            :placeholder="translations.form_step_3_firstname"
            :isRequired="false"
            v-model="claimantAddress.delivery_firstname"
          />
        </div>

        <div class="col-span-6 sm:col-span-3">
          <BaseTextInput
            name="deliveryFamilyName"
            type="text"
            :label="translations.form_step_3_surname"
            :placeholder="translations.form_step_3_surname"
            :isRequired="false"
            v-model="claimantAddress.delivery_lastname"
          />
        </div>

        <div class="col-span-6 sm:col-span-3">
          <BaseTextInput
            name="deliveryStreetAddress"
            type="text"
            :label="translations.form_step_3_address"
            :placeholder="translations.form_step_3_address"
            :isRequired="false"
            v-model="claimantAddress.delivery_address"
          />
        </div>

        <div class="col-span-6 sm:col-span-3">
          <BaseTextInput
            name="deliveryPostalCode"
            type="number"
            :label="translations.form_step_3_psc"
            :placeholder="translations.form_step_3_psc_placeholder"
            :isRequired="false"
            v-model="claimantAddress.delivery_psc"
          />
        </div>

        <div class="col-span-6 sm:col-span-3">
          <BaseTextInput
            name="deliveryCity"
            type="text"
            :label="translations.form_step_3_city"
            :placeholder="translations.form_step_3_city"
            :isRequired="false"
            v-model="claimantAddress.delivery_city"
          />
        </div>

        <div class="col-span-6 sm:col-span-3">
          <BaseSelectInput
            :options="stateOptions"
            name="deliveryState"
            v-model="claimantAddress.delivery_country"
            key-prop="name"
            label-prop="name"
            :label="translations.form_step_3_country"
          />
        </div>

        <div class="col-span-6 sm:col-span-3">
          <BaseTextInput
            name="deliveryCompany"
            type="text"
            :placeholder="translations.form_step_3_company_placeholder"
            :label="translations.form_step_3_company"
            :isRequired="false"
            v-model="claimantAddress.delivery_company"
          />
        </div>
      </div>
    </div>

    <div class="col-span-6">
      <h3 class="text-th-primary-200 font-semibold">
        {{ translations.form_step_3_payment_info }}
      </h3>
      <p class="mt-1 text-sm text-gray-500">
        {{ translations.form_step_3_payment_info_tooltip }}
      </p>
    </div>

    <div class="col-span-6 sm:col-span-3">
      <BaseTextInput
        name="bankAccount"
        type="text"
        :label="translations.form_step_3_bank_acc"
        :placeholder="translations.form_step_3_bank_acc"
        v-model="claimantAddress.bank_account"
        :isRequired="isBankAccRequired"
      />
    </div>

    <!--Footer Navigation-->
    <div
      class="
        flex
        justify-between
        col-span-6
        py-4
        px-2
        bg-th-primary-200-l
        text-right
        border-radious_custom
        text-sm
        md:text-base
      "
    >
      <button
        type="button"
        @click="goBack"
        class="flex justify-between items-center hover:text-th-primary-200"
      >
        <ChevronLeftIcon class="h-5 w-5 text-" />
        {{ translations.root_back }}
      </button>
      <button
        type="submit"
        class="flex justify-between items-center hover:text-th-primary-200"
      >
        {{ translations.root_continue }}
        <ChevronRightIcon class="h-5 w-5 text-" />
      </button>
    </div>
  </Form>
</template>

<script>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { computed } from 'vue'
import { string, object, number } from 'yup'
import { Form } from 'vee-validate'
import BaseTextInput from '../../components/base/BaseTextInput'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/solid'
import BaseSelectInput from '../../components/base/BaseSelectInput'

export default {
  name: 'FormContactInfo',
  components: {
    BaseTextInput,
    BaseSelectInput,
    Form,
    ChevronLeftIcon,
    ChevronRightIcon
  },
  setup() {
    const store = useStore()
    const router = useRouter()

    const translations = computed(() => store.state.translations)
    const claimantAddress = computed(() => store.state.claim.claimant)
    const typeOfRequest = computed(() => store.state.form.typeOfRequest)
    const isDeliveryAddress = computed({
      get: () => store.state.form.isDeliveryAddress,
      set: (value) => {
        store.dispatch('updateFormData', { key: 'isDeliveryAddress', value })
      }
    })
    const isCompanyAddress = computed({
      get: () => store.state.form.isCompanyAddress,
      set: (value) => {
        store.dispatch('updateFormData', { key: 'isCompanyAddress', value })
      }
    })
    const stateOptions = computed(() => {
      return [
        { name: translations.value.form_step_3_country_opt_1 },
        { name: translations.value.form_step_3_country_opt_2 }
      ]
    })

    //form validation
    const schema = computed(() => {
      return object().shape({
        name: string()
          .trim()
          .required(translations.value.root_error_required)
          .nullable()
          .typeError(translations.value.root_string_error)
          .label(translations.value.form_step_3_firstname),
        familyName: string()
          .trim()
          .required(translations.value.root_error_required)
          .nullable()
          .typeError(translations.value.root_string_error)
          .label(translations.value.form_step_3_surname),
        email: string()
          .email(translations.value.root_email_error)
          .required(translations.value.root_error_required)
          .nullable()
          .typeError(translations.value.root_string_error)
          .label(translations.value.form_step_3_email),
        phone: string()
          .trim()
          .required(translations.value.root_error_required)
          .nullable()
          .typeError(translations.value.root_string_error)
          .label(translations.value.form_step_3_phone),
        streetAddress: string()
          .trim()
          .required(translations.value.root_error_required)
          .nullable()
          .typeError(translations.value.root_string_error)
          .label(translations.value.form_step_3_address),
        postalCode: number()
          .positive()
          .required(translations.value.root_error_required)
          .nullable()
          .typeError(translations.value.root_psc_error)
          .label(translations.value.form_step_3_psc),
        city: string()
          .trim()
          .required(translations.value.root_error_required)
          .nullable()
          .typeError(translations.value.root_string_error)
          .label(translations.value.form_step_3_city),
        state: object()
          .required(translations.value.root_error_required)
          .label(translations.value.form_step_3_country)
          .typeError(translations.value.root_error_required),
        deliveryName: string()
          .trim()
          .nullable()
          .label(translations.value.form_step_3_firstname),
        deliveryFamilyName: string()
          .trim()
          .nullable()
          .label(translations.value.form_step_3_surname),
        deliveryStreetAddress: string()
          .trim()
          .nullable()
          .label(translations.value.form_step_3_address),
        deliveryPostalCode: number()
          .label(translations.value.form_step_3_psc)
          .typeError(translations.value.root_psc_error)
          .nullable()
          .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
        deliveryCity: string()
          .trim()
          .nullable()
          .label(translations.value.form_step_3_city),
        deliveryState: object()
          .nullable()
          .label(translations.value.form_step_3_country),
        ic: string()
          .trim()
          .nullable()
          .typeError(translations.value.root_string_error)
          .label(translations.value.form_step_3_ic),
        dic: string()
          .trim()
          .nullable()
          .label(translations.value.form_step_3_dic),
        billingCompany: string()
          .trim()
          .nullable()
          .label(translations.value.form_step_3_company),
        deliveryCompany: string()
          .trim()
          .nullable()
          .label(translations.value.form_step_3_company),
        purchaserId: string()
          .trim()
          .nullable()
          .label(translations.value.form_step_3_purchaser_id),
        bankAccount: bankAccValidation.value
      })
    })

    const isBankAccRequired = computed(() => {
      if (typeOfRequest.value === 'return') {
        return true
      } else
        return !!store.state.claim.items.find(
          (item) => item.requestSolution?.bank_acc_required === 1
        )
    })

    const bankAccValidation = computed(() => {
      if (isBankAccRequired.value) {
        return string()
          .trim()
          .required(() => translations.value.root_error_required)
          .nullable()
          .typeError(() => translations.value.root_string_error)
          .label(() => translations.value.form_step_3_bank_acc)
      } else {
        return string()
          .trim()
          .nullable()
          .label(translations.value.form_step_3_bank_acc)
      }
    })

    function onSubmit() {
      router.push({ name: 'FormSummary' })
    }

    function goBack() {
      if (store.state.form.isManualFill) {
        router.push({ name: 'FormManualFill' })
      } else {
        router.push({ name: 'FormOrderOverview' })
      }
    }

    return {
      schema,
      onSubmit,
      isDeliveryAddress,
      claimantAddress,
      translations,
      goBack,
      bankAccValidation,
      isBankAccRequired,
      stateOptions,
      isCompanyAddress
    }
  }
}
</script>
