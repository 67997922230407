<template>
  <div class="group flex items-center mt-5">
    <ChevronLeftIcon class="inline h-5 w-5 text-th-secondary-300 group-hover:text-gray-800" aria-hidden="true" />
    <router-link to="/" class="text-sm font-medium text-th-secondary-200 group-hover:text-gray-800"><slot></slot></router-link>
  </div>
</template>

<script>
import { ChevronLeftIcon } from "@heroicons/vue/outline";

export default {
  name: "BaseLink",
  components:{ChevronLeftIcon}
};
</script>

<style scoped>

</style>