<template>
  <div>
    <div class="p-5 mb-7 bg-th-tertiary-300 text-th-tertiary-100 flex justify-between">
      <nav>
        <ul role="list" class="flex items-center space-x-4">
          <li>
            <router-link to="/" class="text-sm font-medium hover:text-th-tertiary-200">{{ translations.root_home }}</router-link>
          </li>
        </ul>
      </nav>
      <div v-if="langs.length > 1">
        <ul class="flex gap-1.5">
          <li v-for="(lang, idx) in langs" :key="idx">
            <button @click="setLang(lang)" class="hover:text-th-tertiary-200" :class="{'border-b-2' : lang === currentLang}">{{ lang }}</button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
import getFormSettings from "../services/getFormSettings";

export default {
  components: {},
  setup() {
    const store = useStore()
    const { fetchTranslations, fetchReturnReasons } = getFormSettings()
    const translations = computed(() => store.state.translations)
    const langs = computed(() => store.getters.getLangs)
    const projectId = computed(() => store.getters.getProjectId)
    const currentLang = computed(() => store.getters.getCurrentLang)

    const setLang = async (language) => {
      if (currentLang.value !== language) {
        store.dispatch('setCurrentLang', language)
        let translations = await fetchTranslations({ lang: language })
        store.dispatch('setTranslations', translations)
        let returnReasons = await fetchReturnReasons({ lang: language, project_id: projectId } )
        store.dispatch('setReturnReasons',  returnReasons)
      }
    }

    return { translations, langs, setLang, currentLang }
  }
};
</script>
