<template>
  <div class="flow-root">
    <ul role="list" class="-mb-8">
      <li v-for="(stepItem, stepItemIdx) in returnSteps" :key="stepItem.id">
        <div class="relative pb-8">
          <span
            v-if="stepItemIdx !== returnSteps.length - 1"
            class="absolute top-3 left-3 -ml-px h-full w-0.5 bg-gray-200"
            aria-hidden="true"
          />
          <div class="relative flex items-start space-x-3">
            <div>
              <div class="relative px-1">
                <div
                  class="
                    h-4
                    w-4
                    bg-th-primary-200
                    rounded-full
                    flex
                    items-center
                    justify-center
                  "
                ></div>
              </div>
            </div>
            <div class="min-w-0 flex-1">
              <div>
                <div class="text-sm">
                  <p class="font-medium text-gray-900">
                    {{ stepItem.step }}
                  </p>
                </div>
              </div>
              <div class="mt-2 text-sm text-gray-700">
                <p>
                  {{ stepItem.comment }}
<!--                  <span v-if="stepItem.orderNumber" class="font-bold text-base">-->
<!--                    {{ orderNumber }}</span-->
<!--                  >-->
                </p>
                <p v-if="stepItem.address" class="font-bold mt-1">{{stepItem.address}}</p>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from 'vuex'

export default {
  name: 'FormReturningGuide',
  setup() {
    const store = useStore()

    const translations = computed(() => store.state.translations)
    const returnAddress = computed(() => store.state.settings.return_address
    )
    const returnSteps = ref([
      {
        id: 1,
        step: translations.value.form_step_4_online_form,
        comment: translations.value.form_step_4_online_form_info
      },
      {
        id: 2,
        step: translations.value.form_step_4_packing,
        comment: translations.value.form_step_4_packing_info,
        orderNumber: true
      },
      {
        id: 3,
        step: translations.value.form_step_4_send,
        comment: translations.value.form_step_4_send_info,
        address: returnAddress.value
      },
      {
        id: 4,
        step: translations.value.form_step_4_response,
        comment: translations.value.form_step_4_response_info
      }
    ])

    return {
      returnSteps
    }
  }
}
</script>
