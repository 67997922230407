<template>
  <div class="flex flex-col items-center text-center">
    <!--  <MailIcon class="w-16 sm:w-20 sm:h-20 text-th-primary-200"/>-->
    <component
      :is="status === 'success' ? MailIcon : PresentationChartLineIcon"
      class="w-16 sm:w-20 sm:h-20 text-th-primary-200"
    />
    <h3 class="text-lg sm:text-2xl">
      {{
        status === 'success'
          ? translations.form_step_5_heading
          : translations.form_step_5_heading_error
      }}
    </h3>
    <p class="text-sm mt-2">
      {{
        status === 'success'
          ? translations.form_step_5_info_1
          : translations.form_step_5_info_1_error
      }}
    </p>
    <p class="text-sm mt-2">
      {{
        status === 'success'
          ? translations.form_step_5_info_2
          : translations.form_step_5_info_2_error
      }}
    </p>
    <div class="flex flex-wrap justify-center gap-3 mt-5">
      <BaseButton @btn-click="$router.push({ name: 'Home' })">
        {{
          status === 'success'
            ? translations.form_step_5_close
            : translations.root_home
        }}
      </BaseButton>

      <!--Form download-->
      <BaseButton
        v-if="status === 'success' && formPdfUrl"
        @btn-click="downloadForm"
        color="dark"
        :disabled="downloadLoading"
      >
        <p class="flex items-center gap-1.5">
          <span>{{ translations.form_step_5_download }}</span>
          <LoadingIcon v-if="downloadLoading" />
          <DownloadIcon v-else class="h-5 w-5 inline" />
        </p>
      </BaseButton>
    </div>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { MailIcon, PresentationChartLineIcon } from '@heroicons/vue/outline'
import { useStore } from 'vuex'
import { computed, ref } from "vue";
import BaseButton from '../../components/base/BaseButton'
import { DownloadIcon } from '@heroicons/vue/solid'
import downloadFile from "../../services/downloadFile";
import LoadingIcon from "../../components/LoadingIcon";

export default {
  name: 'FormClosure',
  components: { LoadingIcon, BaseButton, MailIcon, PresentationChartLineIcon, DownloadIcon },
  setup() {
    const store = useStore()
    const route = useRoute()
    const formPdfUrl = computed(() => store.state.pdfUrl)
    const downloadLoading = ref(false)

    const status = computed(() => route.params.status)

    const translations = computed(() => store.state.translations)

    const downloadForm = async () => {
      downloadLoading.value = true
      let fileName
      let matches = formPdfUrl.value.match(/\/([^/?#]+)[^/]*$/);
      if (matches.length > 1) {
        fileName = matches[1]
      } else {
        fileName = 'form.pdf'
      }

      try {
        await downloadFile(formPdfUrl.value, fileName)
      } catch (err) {
        console.log(err.message())
      } finally {
        downloadLoading.value = false
      }

    }
    return {
      downloadForm,
      translations,
      status,
      MailIcon,
      PresentationChartLineIcon,
      formPdfUrl,
      downloadLoading
    }
  }
}
</script>

<style scoped></style>
