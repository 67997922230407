<template>
  <div  class="fixed flex flex-col inset-0 gap-3 items-center justify-center p-2 text-gray-700">
    <div class="max-w-sm flex flex-col items-center">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 mb-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z" />
      </svg>
      <h1 class="font-bold text-lg">Ooops!</h1>
      <p class="text-center">Vapadá to, že se něco pokazilo...</p>
      <p class="text-center">Zkuste prosím načíst formulář později.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorInitialLoad"
};
</script>
