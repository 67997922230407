<template>
  <button
    :type="type"
    class="
      px-4
      py-2
      border border-transparent
      font-medium
      border-radious_custom
      shadow-sm
      focus:outline-none focus:ring-1 focus:ring-offset-1
      transition
      disabled:opacity-50
      disabled:cursor-not-allowed
    "
    :class="
      color === 'light'
        ? 'text-th-secondary-400 border-th-primary-200 hover:border-th-primary-200  hover:text-th-tertiary-100 hover:bg-th-primary-200 focus:ring-th-primary-200'
        : 'bg-th-primary-200 text-th-tertiary-100 hover:bg-th-primary-300 focus:ring-th-primary-200'
    "
    @click="$emit('btnClick')"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'BaseButton',
  emits: ['btnClick'],
  props: {
    type: {
      type: String,
      default: 'button'
    },
    color: {
      type: String,
      default: 'light'
    }
  }
}
</script>

<style scoped></style>
