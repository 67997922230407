<template>
  <TheHeader>
    <template v-slot:header>
      {{ translations.form_step_4_online_form }}
    </template>
    <template v-slot:button>
      <router-link
        to="/"
        class="
          group
          flex
          items-center
          gap-1
          text-th-secondary-300
          hover:text-th-primary-200
        "
      >
        <div class="text-sm md:text-base font-medium">
          <span class="hidden md:inline">{{
            translations.root_back_full
          }}</span>
          <span class="md:hidden">{{ translations.root_back }}</span>
        </div>
        <LogoutIcon
          class="inline h-5 w-5 text-th-primary-200"
          aria-hidden="true"
        />
      </router-link>
    </template>
  </TheHeader>
  <div
    class="
      bg-th-primary-200-l bg-opacity-25
      p-2
      lg:pb-12
      sm:p-5
      border-radious_custom
      space-y-2
      lg:space-y-5
    "
  >
    <div class="lg:grid lg:grid-cols-3 lg:gap-6 items-stretch">
      <h2
        class="
          md:col-start-2 md:col-span-2
          text-xl
          md:text-2xl
          font-bold
          leading-7
          text-th-primary-200 text-center
        "
      >
        {{ mainHeading }}
      </h2>
    </div>
    <div class="lg:grid lg:grid-cols-3 lg:gap-6 items-stretch">
      <!--Navigace-->
      <div class="md:col-span-1">
        <div class="px-4 sm:px-0">
          <FormStepsNavbar />
        </div>
      </div>
      <!--Formulář-->
      <div
        class="mt-5 md:mt-0 md:col-span-2 shadow border-radious_custom bg-white"
      >
        <div
          class="
            px-2
            sm:px-4
            py-5
            bg-white
            space-y-5
            sm:p-6
            h-full
            border-radious_custom
          "
        >
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import getOrder from '../services/getOrder'
import TheHeader from '../components/TheHeader'
import FormStepsNavbar from '../components/form/FormStepsNavbar'
import { LogoutIcon } from '@heroicons/vue/outline'

export default {
  name: 'OnlineForm',
  components: { FormStepsNavbar, TheHeader, LogoutIcon },
  setup() {
    const store = useStore()
    const router = useRouter()
    const { fetchData, error } = getOrder()

    const translations = computed(() => store.state.translations)
    const typeOfRequest = computed(() => store.state.form.typeOfRequest)

    const mainHeading = computed(() => {
      if (typeOfRequest.value) {
        return typeOfRequest.value === 'complain'
          ? translations.value.claim_type_heading
          : translations.value.return_type_heading
      } else {
        return 'Reklamační formulář'
      }
    })

    async function reFetchOrder() {
      let orderKeys = JSON.parse(sessionStorage.getItem('order_keys'))
      const data = await fetchData(JSON.stringify(orderKeys))
      if (!error.value) {
        store.dispatch('setOrder', data)
      } else {
        sessionStorage.removeItem('order_keys')
        router.push({ name: 'OrderSearch' })
      }
    }
    // znovu načíst údaje o objednávce při znovunačtení rozepsaného formuláře
    if (sessionStorage.getItem('order_keys')) {
      reFetchOrder()
    }

    //Načtení dat druhu požadavku(reklamace nebo vrácení) ze sessionStore
    if (
      sessionStorage.getItem('typeOfRequest') &&
      !store.state.claim.claim_type
    ) {
      try {
        const request = sessionStorage.getItem('typeOfRequest')
        store.dispatch('setTypeOfRequest', request)
      } catch (e) {
        localStorage.removeItem('typeOfRequest')
      }
    }

    return { typeOfRequest, translations, mainHeading }
  }
}
</script>

<style scoped></style>
