<template>
  <div v-if="returnedProducts.length" class="space-y-5">
    <div>
      <h3 class="text-lg text-th-primary-200 font-semibold">
        {{
          typeOfRequest === 'return'
            ? translations.form_step_4_return_heading
            : translations.form_step_4_claim_heading
        }}
      </h3>
      <p class="mt-1 text-sm text-th-secondary-200">
        {{ translations.form_step_4_check }}
      </p>
    </div>

    <!--Zákazník -->
    <div
      class="
        flex
        justify-between
        flex-wrap
        gap-3
        px-6
        py-3
        text-th-secondary-400 text-sm
        border border-gray-200
        border-radious_custom
        shadow-sm
      "
    >
      <div class="grid grid-rows-3 gap-y-1">
        <span class="text-th-secondary-200 text-xs font-medium">{{
          translations.form_step_4_customer
        }}</span>
        <span class=""
          >{{ claimant.billing_firstname }}
          {{ claimant.billing_lastname }}</span
        >
        <span>{{ claimant.email }}</span>
      </div>
      <div class="grid grid-rows-3">
        <template v-if="isManualFill">
          <span class="text-th-secondary-200 text-xs font-medium">{{
            translations.root_invoice_id
          }}</span>
          <span class="">{{ claim.invoice_id }}</span>
        </template>
        <template v-else>
          <span class="text-th-secondary-200 text-xs font-medium">{{
            translations.root_order
          }}</span>
          <span class="">{{ order.order_no }}</span>
          <span>{{ orderDate }}</span>
        </template>
      </div>
      <div class="grid grid-rows-3">
        <span class="text-th-secondary-200 text-xs font-medium">{{
          translations.form_step_3_bank_acc
        }}</span>
        <span class="">{{ claimant.bank_account }}</span>
      </div>
    </div>

    <!--  Vybrané produkty k vrácení-->
    <div class="flow-root">
      <h4 class="text-sm font-medium text-th-primary-200 mb-3">
        {{
          typeOfRequest === 'return'
            ? translations.form_step_4_return_products
            : translations.form_step_4_claim_products
        }}
      </h4>
      <FormClaimProductsManual v-if="isManualFill" :items="claim.items" />
      <ul
        v-else
        role="list"
        class="-my-6 divide-y divide-gray-200 border-b border-gray-200"
      >
        <li
          v-for="product in returnedProducts"
          :key="product.code"
          class="py-6 flex flex-col gap-4"
        >
          <div class="flex">
            <div
              class="
                flex-shrink-0
                w-24
                h-24
                border border-gray-200
                rounded-md
                overflow-hidden
              "
            >
              <img
                v-if="product.imageSrc"
                :src="product.imageSrc"
                :alt="product.imageAlt"
                class="w-full h-full object-center object-cover"
              />
              <img
                v-else
                src="@/assets/images/image-placeholder-icon.jpg"
                alt="produkt"
                class="w-full h-full object-center object-cover"
              />
            </div>

            <div class="ml-4 flex-1 flex flex-col">
              <div>
                <div
                  class="
                    flex
                    justify-between
                    text-base
                    font-medium
                    text-gray-900
                  "
                >
                  <h4>
                    <a :href="product.href">
                      {{ product.name }}
                    </a>
                  </h4>
                  <div class="flex ml-4 flex-shrink-0 w-24">
                    <span class="ml-auto"
                      >{{ product.price }}
                      {{ translations.form_step_2_currency }}</span
                    >
                  </div>
                </div>
                <p class="mt-1 text-sm text-gray-500">
                  {{ product.attribute }}
                </p>
              </div>
              <!--              &lt;!&ndash;            Důvod vrácení a ks - more than small screen&ndash;&gt;-->
              <!--              <div class="hidden sm:flex flex-1 flex-wrap justify-between items-end text-sm">-->
              <!--                <div>-->
              <!--                  <span class="text-gray-500 mr-1">Vracím z důvodu:</span>-->
              <!--                  <span>{{ product.requestData.reasonOfReturning ? product.requestData.reasonOfReturning.name :product.requestData.reasonOfComplain }}</span>-->
              <!--                </div>-->
              <!--                <div>-->
              <!--                  <span class="text-gray-500">Počet kusů</span>-->
              <!--                  <span class="ml-3.5">{{ product.requestData.quantityReturn }}</span>-->
              <!--                </div>-->
              <!--              </div>-->
            </div>
          </div>
          <!--        Důvod vrácení a ks - only small screen-->
          <div
            class="
              flex flex-1 flex-wrap
              gap-y-2
              justify-between
              items-end
              text-sm
            "
          >
            <div>
              <span class="text-gray-500 mr-1">
                {{ translations.form_step_2_return_reason }}</span
              >
              <span>{{
                product.requestData.reasonOfReturning
                  ? product.requestData.reasonOfReturning.name
                  : product.requestData.reasonOfComplain
              }}</span>
            </div>
            <div v-if="product.requestData.requestSolution">
              <span class="text-gray-500"
                >{{ translations.form_step_2_claim_solution }}:</span
              >
              <span class="ml-3.5">{{
                product.requestData.requestSolution.name
              }}</span>
            </div>
            <div>
              <span class="text-gray-500"
                >{{ translations.form_step_2_quantity }}:</span
              >
              <span class="ml-3.5"
                >{{ product.requestData.quantityReturn }} Ks</span
              >
            </div>
          </div>
        </li>
      </ul>
    </div>
    <!--Dodatečné informace-->
    <div class="pt-5 text-sm font-medium text-th-primary-200">
      <label for="msg">{{ translations.form_step_4_additional_info }}</label>
      <textarea
        v-model="finalMessage"
        class="text-gray-800"
        autofocus
        id="msg"
        :placeholder="translations.form_step_4_additional_info_placeholder"
        rows="4"
        autocomplete="off"
      ></textarea>
    </div>

    <!--  Postup vrácení-->
    <div class="">
      <h4 class="text-lg text-th-primary-200 font-semibold mb-3">
        {{ translations.form_step_4_guidelines }}
      </h4>

      <div class="mt-5 p-3 bg-gray-50 text-sm">
        <FormReturningGuide />
      </div>
    </div>

    <!--Podmínky-->
    <div class="text-sm text-gray-500 p-3">
      <p>
        {{ translations.form_step_4_consent }}
        <a
          :href="settings.terms_of_trade_url"
          class="text-th-primary-200 underline"
          >{{ translations.form_step_4_terms_of_trade }}</a
        >
        {{ translations.form_step_4_consent_and }}
        <a
          :href="settings.privacy_policy_url"
          class="text-th-primary-200 underline"
          >{{ translations.form_step_4_privacy_policy }}</a
        >.
      </p>
    </div>

    <div
      class="
        col-span-6
        flex
        justify-between
        px-2
        py-2
        bg-th-primary-200-l
        text-right
        border-radious_custom
        text-sm
        md:text-base
      "
    >
      <button
        @click="$router.push({ name: 'FormContactInfo' })"
        type="button"
        class="flex justify-between items-center hover:text-th-primary-200"
      >
        <ChevronLeftIcon class="h-5 w-5 text-" />
        {{ translations.root_back }}
      </button>
      <BaseButton color="dark" @btn-click="handleSubmit" class="flex gap-1.5" :disabled="loading">
        <LoadingIcon v-if="loading"/>
        {{ translations.root_submit }}
      </BaseButton>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { computed, ref } from "vue";
import createClaim from '../../services/createClaim'
import FormReturningGuide from '../../components/form/FormReturningGuide'
import { ChevronLeftIcon } from '@heroicons/vue/solid'
import BaseButton from '../../components/base/BaseButton'
import FormClaimProductsManual from '../../components/form/FormClaimProductsManual'
import LoadingIcon from '../../components/LoadingIcon'

export default {
  name: 'FormSummary',
  components: {
    LoadingIcon,
    FormClaimProductsManual,
    BaseButton,
    FormReturningGuide,
    ChevronLeftIcon
  },
  setup() {
    const store = useStore()
    const { postData, error } = createClaim()
    const router = useRouter()

    const loading = ref(false)
    const translations = computed(() => store.state.translations)
    const claimant = computed(() => store.state.claim.claimant)
    const isManualFill = computed(() => store.state.form.isManualFill)
    const claim = computed(() => store.state.claim)
    // const returnedProducts = computed(() => store.getters.getReturnedProducts);
    const orderDate = computed(() => store.getters.getOrderDate)
    const order = computed(() => store.state.form.order)
    const typeOfRequest = computed(() => store.state.form.typeOfRequest)
    const settings = computed(() => store.state.settings)

    const returnedProducts = computed(() => {
      if (store.state.form.isManualFill) {
        return claim.value.items
      } else {
        return store.getters.getReturnedProducts
      }
    })

    const finalMessage = computed({
      get: () => store.state.claim.msg,
      set: (value) => {
        store.dispatch('updateClaimData', { key: 'msg', value })
      }
    })

    const handleSubmit = async () => {
      loading.value = true

      /* handle attachments */
      let files = new FormData()
      for (let i = 0; i < claim.value.attachments.length; i++) {
        let file = claim.value.attachments[i]
        files.append('attachments[' + i + ']', file)
      }

      /* handle form data */
      let data
      // Manuální vyplnění
      if (isManualFill.value) {
        let claimItems = []
        claim.value.items.forEach((item) => {
          claimItems.push({
            id: item.id,
            product_name: item.name,
            code: item.productCode,
            reason: item.reasonOfComplain,
            claim_solution_id: item.requestSolution
              ? item.requestSolution.id
              : null,
            return_reason_id: item.reasonOfReturning
              ? item.reasonOfReturning.id
              : null,
            quantity: item.quantityReturn
          })
        })

        data = {
          project_id: store.state.project_id,
          claim_type: claim.value.claim_type,
          invoice_number: claim.value.invoice_id,
          order_id: claim.value.order_id,
          msg: claim.value.msg,
          items: claimItems,
          claimant: Object.assign(claimant.value, {
            billing_country: claimant.value.billing_country.name,
            delivery_country: claimant.value.delivery_country?.name
          }),
          lang: document.documentElement.lang
        }
        await postData('/manual/claim', data, files)
      } else {
        // Automatické vyplnění
        data = {
          project_id: store.state.project_id,
          claim_type: claim.value.claim_type,
          order_id: claim.value.order_id,
          msg: claim.value.msg,
          items: claim.value.items,
          claimant: Object.assign(claimant.value, {
            billing_country: claimant.value.billing_country.name,
            delivery_country: claimant.value.delivery_country?.name
          }),
          customer: claim.value.customer,
          lang: document.documentElement.lang
        }

        await postData(process.env.VUE_APP_API_URL_CREATE_CLAIM, data, files)
      }

      if (!error.value) {
        router.push({ name: 'FormClosure', params: { status: 'success' } })
        loading.value = false
      } else {
        router.push({ name: 'FormClosure', params: { status: 'error' } })
        loading.value = false
      }
      store.dispatch('resetForm')
      sessionStorage.removeItem('order_keys')
      sessionStorage.removeItem('typeOfRequest')
    }

    return {
      claimant,
      returnedProducts,
      order,
      orderDate,
      handleSubmit,
      finalMessage,
      typeOfRequest,
      settings,
      translations,
      claim,
      isManualFill,
      loading
    }
  }
}
</script>
