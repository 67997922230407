<template>
  <div class="mb-4 sm:mb-8 pb-2 border-b border-gray-200 ">
    <div class="flex flex-wrap justify-between">
      <h1 class="text-2xl font-bold leading-7 text-th-secondary-400 sm:text-3xl">
        <slot name="header">
        </slot>
      </h1>
      <slot name="button">

      </slot>
    </div>
    <p class="mt-2 text-sm text-th-secondary-200">
      <slot name="description">
      </slot>
    </p>
  </div>
</template>

<script>
export default {
  name: "Header",
};
</script>

<style scoped></style>
