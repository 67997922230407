<template>
  <!--Mobile view-->
  <div class="flex items-center justify-between">
    <h3 class="text-lg text-th-primary-200 font-semibold">
      {{ translations.form_manual_step_2_heading }}
    </h3>
    <BaseButton color="dark" class="text-xs" @btn-click="newSearch"
      >{{translations.root_back}}</BaseButton
    >
  </div>
  <form class="space-y-5">
    <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
      <div class="col-span-6 sm:col-span-3">
        <BaseTextInput
          name="invoice"
          type="text"
          v-model="claim.invoice_id"
          :label="translations?.root_invoice_id"
          :rules="atLeastOne"
          :is-required="false"
          :showErrorColor="false"
        />
      </div>

      <div class="col-span-6 sm:col-span-3">
        <BaseTextInput
          name="orderId"
          type="text"
          v-model="claim.order_id"
          :label="translations.form_step_1_order_no"
          :is-required="false"
        />
      </div>
    </div>

    <!--  Seznam Produktů-->

    <div>
      <h4 class="text-base font-medium text-th-primary-200">
        {{
          typeOfRequest === 'return'
            ? translations.form_manual_step_2_return_product_list
            : translations.form_manual_step_2_claim_product_list
        }}
      </h4>
      <p class="mt-1 text-sm text-gray-500">
        {{
          typeOfRequest === 'return'
            ? translations.form_manual_step_2_return_product_list_desc
            : translations.form_manual_step_2_claim_product_list_desc
        }}
      </p>
    </div>

    <div class="space-y-2.5">
      <div
        v-for="(item, idx) in items"
        :key="item.id"
        class="
          whitespace-nowrap
          text-sm
          font-medium
          text-gray-900
          bg-white
          shadow-sm
        "
      >
        <div
          class="
            relative
            grid grid-cols-1
            gap-y-1 gap-x-4
            md:grid-cols-6
            border border-th-primary-200-l
            md:py-5
            md:px-7
            px-3
            py-2
            border-radious_custom
          "
        >
          <div class="col-span-1 md:col-span-3">
            <BaseTextInput
              :name="'productName' + item.id"
              type="text"
              v-model="item.name"
              :label="translations.root_product_name"
              class="flex-grow"
              :rules="isStringRequired(translations.root_product_name)"
            />
          </div>
          <div class="col-span-1 md:col-span-3 flex flex-wrap gap-2 justify-between">
            <BaseTextInput
              :name="'productCode' + item.id"
              type="text"
              v-model="item.productCode"
              :label="translations.root_product_code"
              class="w-28"
              :rules="isStringRequired(translations.root_product_code)"
            />
            <BaseTextInput
              :name="'quantity' + item.id"
              type="number"
              v-model="item.quantityReturn"
              :label="translations.form_step_2_quantity"
              class="min-w-min w-28"
              :rules="isNumber(translations.form_step_2_quantity)"
            />
          </div>
          <div
            v-if="typeOfRequest === 'complain'"
            class="col-span-1 md:col-span-6"
          >
            <BaseTextareaInput
              :name="'complainReason' + item.id"
              v-model="item.reasonOfComplain"
              :placeholder="translations.form_step_2_claim_reason_placeholder"
              :label="translations.form_step_2_claim_reason"
              :rules="isStringRequired(translations.form_step_2_claim_reason)"
            />
          </div>
          <div class="col-span-1 md:col-span-3">
            <!--reklamace-->
            <BaseSelectInput
              v-if="typeOfRequest === 'complain' &&  showRequestSolution"
              :options="requestSolution"
              :name="'request' + item.id"
              v-model="item.requestSolution"
              key-prop="name"
              label-prop="name"
              :label="translations.form_step_2_claim_solution"
              :rules="isRequired(translations.form_step_2_claim_solution)"
            />
            <!--vrácení-->
            <BaseSelectInput
              v-if="typeOfRequest === 'return'"
              :options="returnReasons"
              :name="'reason' + item.id"
              v-model="item.reasonOfReturning"
              key-prop="name"
              label-prop="name"
              :label="translations.form_step_2_return_reason"
              :rules="isRequired(translations.form_step_2_return_reason)"
            />
          </div>

          <!--Delete button-->
          <div
            class="
              absolute
              top-0
              right-0
              h-6
              flex
              divide-x
              rounded-bl-lg rounded-tr-lg
              border-gray-300 border-b border-l
              overflow-hidden
            "
          >
            <button
              @click="deleteLine(idx)"
              type="button"
              class="
                flex
                items-center
                justify-center
                w-6
                text-red-600
                hover:bg-gray-50
                focus:outline-none focus:bg-gray-50
              "
            >
              <span class="sr-only"> Delete </span>
              <svg
                class="w-4 h-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
      <button
        v-if="typeOfRequest === 'return'"
        @click="addLine"
        type="button"
        class="
          w-full
          mt-5
          h-9
          px-4
          inline-flex
          space-x-1
          items-center
          justify-center
          font-medium
          tracking-tight
          border-radious_custom
          text-th-primary-200
          bg-white
          border border-gray-200
          hover:border-th-primary-200
          focus:outline-none
          focus:ring-1
          focus:ring-th-primary-200
          focus:text-th-primary-200
          focus:bg-primary-50
          focus:border-th-primary-200
        "
      >
        <svg
          class="w-5 h-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
            clip-rule="evenodd"
          ></path>
        </svg>
        <span>{{translations.root_add_item}}</span>
      </button>
    </div>
  </form>

  <!--  Přílohy-->
  <div>
    <h4 class="text-base font-medium text-th-primary-200">
      {{
        typeOfRequest === 'return'
          ? translations.form_manual_step_2_return_attachments
          : translations.form_manual_step_2_claim_attachments
      }}
    </h4>
    <p class="mt-1 text-sm text-gray-500">
      {{
        typeOfRequest === 'return'
          ? translations.form_manual_step_2_return_attachments_desc
          : translations.form_manual_step_2_claim_attachments_desc
      }}
    </p>
    <div class="my-2" v-if="attachments">
      <ul>
        <li
          v-for="(file, index) in attachments"
          :key="index"
          class="flex items-start justify-between mb-2.5"
        >
          <div class="flex">
            <DocumentTextIcon class="h-5 w-5 text-th-primary-200" />
            <span class="text-sm break-all">{{ file.name }}</span>
          </div>
          <button
            @click="removeFile(file)"
            type="button"
            class="ml-2 text-th-primary-200 hover:text-red-700"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </li>
      </ul>
    </div>
    <div class="mt-2">
      <BaseInputUploadFile
        @drop.prevent="addDropFiles"
        @change="addSelectFiles"
      />
    </div>
  </div>

  <!--  Footer Navigation-->
  <div
    class="
      flex
      justify-between
      py-4
      px-2
      bg-th-primary-200-l
      text-right
      border-radious_custom
      text-sm
      md:text-base
    "
  >
    <button
      type="button"
      @click="newSearch"
      class="flex justify-between items-center hover:text-th-primary-200"
    >
      <ChevronLeftIcon class="h-5 w-5 text-" />
      {{ translations.root_back }}
    </button>
    <button
      type="submit"
      @click="onSubmit"
      class="flex justify-between items-center hover:text-th-primary-200"
    >
      {{ translations.root_continue }}
      <ChevronRightIcon class="h-5 w-5 text-" />
    </button>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import BaseButton from '../../components/base/BaseButton'
import { useRouter } from 'vue-router'
import BaseTextInput from '../../components/base/BaseTextInput'
import BaseSelectInput from '../../components/base/BaseSelectInput'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/solid'
import { useForm } from 'vee-validate'
import { number, string, object } from 'yup'
import { DocumentTextIcon } from '@heroicons/vue/outline'
import BaseInputUploadFile from '../../components/base/BaseInputUploadFile'
import BaseTextareaInput from '../../components/base/BaseTextareaInput'

export default {
  name: 'FormManualFill',
  components: {
    BaseTextareaInput,
    BaseButton,
    BaseTextInput,
    BaseSelectInput,
    ChevronLeftIcon,
    ChevronRightIcon,
    DocumentTextIcon,
    BaseInputUploadFile
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const { handleSubmit } = useForm({})
    const translations = computed(() => store.state.translations)
    const returnReasons = computed(() => store.getters.getReturnReasons)
    const typeOfRequest = computed(() => store.state.form.typeOfRequest)
    const claim = computed(() => store.state.claim)
    const claimItems = computed(() => store.state.claim.items)
    const attachments = computed(() => store.state.claim.attachments)
    const requestSolution = computed(() => store.state.settings.claim_solutions)
    const showRequestSolution = computed(() => store.state.settings.show_claim_solutions)

    const newSearch = () => {
      router.push({ name: 'OrderSearch' })
    }

    /*Tvorba seznamu produktů*/
    const items = ref([])
    items.value = claimItems.value
    const addLine = () => {
      items.value.push({
        id: Date.now(),
        name: null,
        reasonOfReturning: null,
        reasonOfComplain: null,
        requestSolution: null,
        quantityReturn: 1,
        productCode: null
      })
    }

    if (!items.value.length) {
      addLine()
    }

    const deleteLine = (idx) => {
      items.value.splice(idx, 1)
      if (!items.value.length) {
        addLine()
      }
    }

    /*Drop and drag*/

    // const fileSelectError = false
    const filesNames = computed(() => store.getters.getAttachmentsNames)

    const addDropFiles = (e) => {
      const droppedFiles = e.dataTransfer.files

      if (!droppedFiles) return
      let file
      for (let i = 0; i < droppedFiles.length; i++) {
        file = droppedFiles[i]
        if (!filesNames.value.includes(file.name)) {
          // dropzoneFiles.value.push(file)
          store.dispatch('addAttachment', file)
        }
      }
    }

    const addSelectFiles = () => {
      let selectedFiles = document.querySelector('.dropzoneFile').files
      let file
      for (let i = 0; i < selectedFiles.length; i++) {
        file = selectedFiles[i]
        if (!filesNames.value.includes(file.name)) {
          store.dispatch('addAttachment', file)
        }
      }
    }

    const removeFile = (file) => {
      store.dispatch('removeAttachment', file)
    }

    /*Validation*/
    const isStringRequired = (label) => {
      return string()
        .trim()
        .required(() => translations.value.root_error_required)
        .label(label)
        .typeError(() => translations.value.root_error_required)
    }
    const isRequired = (label) => {
      return object()
        .required(() => translations.value.root_error_required)
        .label(label)
        .typeError(() => translations.value.root_error_required)
    }

    const isNumber = (label) => {
      return number()
        .min(1, () => translations.value.form_step_2_quantity_error)
        .required(() => translations.value.root_error_required)
        .label(label)
        .typeError(() => translations.value.root_error_required)
    }

    const atLeastOne = () => {
      if(claim.value.invoice_id || claim.value.order_id) {
        return true
      }
      else return translations.value.form_step_2_invoice_or_order_error
    }

    const onSubmit = handleSubmit(() => {
      store.dispatch('setClaimItems', {
        formType: 'manual',
        items: items.value
      })
      router.push({ name: 'FormContactInfo' })
    })

    return {
      translations,
      returnReasons,
      newSearch,
      claim,
      typeOfRequest,
      requestSolution,
      addLine,
      deleteLine,
      onSubmit,
      isRequired,
      isNumber,
      isStringRequired,
      attachments,
      addDropFiles,
      addSelectFiles,
      removeFile,
      items,
      showRequestSolution,
      atLeastOne
    }
  }
}
</script>

<style scoped></style>
