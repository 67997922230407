import {ref} from "vue";
import store from "../store";
import apiClient from "./apiClient";

const getOrder = () => {
    const error = ref(null);
    const loading = ref(false);

    const fetchData = async (payload = {}) => {
        error.value = null;
        loading.value = true;
        try {
            const result = await apiClient().post(process.env.VUE_APP_API_URL_GET_ORDER,
                {
                    ...payload,
                    'project_id': store.state.project_id,
                }
            );
            return result.data;
        } catch (err) {
            error.value =
                "Číslo objednávky se neshoduje se zadanou E-mailovou adresou.";
        } finally {
            loading.value = false;
        }
    };

    return {
        fetchData,
        error,
        loading,
    };
};

export default getOrder;
