import axios from 'axios'

const apiClient = (
    header = {Accept: 'application/json', 'Content-Type': 'application/json'}
) => {
    return axios.create({
        baseURL: process.env.VUE_APP_API_URL,
        withCredentials: false,
        headers: header
    })
}

export default apiClient
