<template>
  <div class="flex flex-col justify-between h-full space-y-5">
    <div class="space-y-5">
      <h3 class="text-lg text-th-primary-200 font-semibold">{{ translations.form_step_1_name }}</h3>
      <p v-if="error" class="text-sm text-red-600">{{ translations.form_step_1_order_search_error }}</p>
      <Form
        @submit="onSubmit"
        :validation-schema="schema"
        v-if="!isPending && Object.keys(translations).length !== 0"
        class="grid grid-cols-6 gap-3"
      >
        <div class="col-span-6 sm:col-span-3">
          <BaseInput
            name="order_id"
            type="text"
            :label="translations.form_step_1_order_no"
            :placeholder="translations.form_step_1_order_no"
          />
        </div>

        <div class="col-span-6 sm:col-span-3">
          <BaseInput
            name="email"
            type="email"
            :label="translations.form_step_1_email"
            :placeholder="translations.form_step_1_email_placeholder"
          />
        </div>

        <div class="col-span-6 flex justify-center">
          <BaseButton type="submit" class="flex justify-center" color="dark">{{ translations.form_step_1_search_btn }}
            <SearchIcon class="ml-3 -mr-1 h-5 w-5" />
          </BaseButton>
        </div>
      </Form>

      <div v-else>
        <Spinner />
      </div>
    </div>

    <button class="inline-flex justify-start text-sm font-medium text-th-secondary-200 underline hover:text-th-primary-200"><router-link :to="{name: 'FormManualFill'}">{{translations.form_step_1_manual_fill}}</router-link></button>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { computed, ref } from "vue";
import { useRouter } from 'vue-router'
import { Form } from 'vee-validate'
import { string, object } from 'yup'
import getOrder from '../../services/getOrder'
import useSessionStore from '../../composables/useSessionStore'
import BaseInput from '../../components/base/BaseInput'
import Spinner from '../../components/Spinner'
import { SearchIcon } from '@heroicons/vue/solid'
import BaseButton from "../../components/base/BaseButton";


export default {
  name: 'OrderSearch',
  components: { BaseButton, Spinner, BaseInput, SearchIcon, Form },
  setup() {
    const router = useRouter()
    const store = useStore()
    const { setSessionStore } = useSessionStore()
    const { fetchData, error } = getOrder()
    const isPending = ref(false)
    const translations = computed(() => store.state.translations)

    //form validation
    const schema = object().shape({
      order_id: string()
        .trim()
        .typeError(translations.value.form_step_1_order_error)
        .required(translations.value.root_error_required)
        .label(translations.value.form_step_1_order_no),
      email: string()
        .trim()
        .email(translations.value.root_email_error)
        .required(translations.value.root_error_required)
        .label(translations.value.form_step_1_email)
    })

    const onSubmit = async (values) => {
      isPending.value = true

      // stažení dat objednávky
      const data = await fetchData(values)
      if (!error.value) {
        //uložení dat o objednávce
        await store.dispatch('setOrder', data)
        //uložení emailu a č.obj. do session storage
        let formData = JSON.stringify(values, null, 2)
        setSessionStore('order_keys', formData)
        await router.push({ name: 'FormOrderOverview' })
      }
      isPending.value = false
    }

    return { isPending, schema, onSubmit, error, translations }
  }
}
</script>
