import { ref } from "vue";
import apiClient from "./apiClient";

const getFormSettings = () => {
  const error = ref(null);
  const loading = ref(false);

  const fetchSetting = async (projectId) => {
    error.value = null;
    loading.value = true;
    try {
      let result = await apiClient().get("/form_settings", { params: {project_id : projectId}});
      return result.data;
    } catch (err) {
      error.value = "Stažení nastavení formuláře selhalo";
      return Promise.reject(err)
    } finally {
      loading.value = false;
    }
  };

  const fetchTranslations = async (payload = { lang: "cs" }) => {
    error.value = null;
    loading.value = true;
    try {
      let result = await apiClient().post("/translations", payload);
      return result.data;
    } catch (err) {
      error.value = "Stažení překladů selhalo";
      return Promise.reject(err)
    } finally {
      loading.value = false;
    }
  };

  const fetchReturnReasons = async (payload = { lang: "cs", project_id: null }) => {
    error.value = null;
    loading.value = true;
    try {
      let result = await apiClient().post("/return_reasons", payload);
      return result.data;
    } catch (err) {
      error.value = "Stažení důvodů vrácení selhalo";
      return Promise.reject(err)
    } finally {
      loading.value = false;
    }
  };

  return {
    fetchSetting,
    fetchTranslations,
    fetchReturnReasons,
    error,
    loading,
  };
};

export default getFormSettings;
